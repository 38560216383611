import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { debounce } from "lodash";

import Filtros from "./Filtros.jsx";

import {
  abrirModalBuscaAvancada,
  handleChangeStatusAtividade,
  handleChangeCampoBusca,
  handleAtualizarFitrosBuscaAcancada,
} from "../../../../store/reducers/baseLeads/baseLeadsActions";

const onscroll = () => {
  const filtrosBaseLeads = document.getElementById("filtrosBaseLeads");
  if (!filtrosBaseLeads) return;
  const sticky = filtrosBaseLeads.offsetTop;

  if (window.scrollTop > sticky) {
    filtrosBaseLeads.style.position = "fixed";
    filtrosBaseLeads.style.width = "calc(100% - 64px)";
    filtrosBaseLeads.style.top = "57px";
    filtrosBaseLeads.style.zIndex = "1";
  } else {
    filtrosBaseLeads.removeAttribute("style");
  }
};

class FiltrosContainer extends React.Component {
  constructor(props) {
    super(props);

    window.onscroll = onscroll;
  }

  state = { campoBusca: "" };

  componentDidMount = () => this.setState({ campoBusca: this.props.campoBuscaRedux });

  handleClickFiltroAvancada = () => this.props.abrirModalBuscaAvancada();

  handleClickStatusAtividade = event => this.props.handleChangeStatusAtividade(event.currentTarget.id);

  handleChangeCampoBusca = event => {
    const { value } = event.currentTarget;
    this.setState({ campoBusca: value });

    this.buscarLeads();
  };

  buscarLeads = debounce(() => {
    this.props.handleChangeCampoBuscaRedux(this.state.campoBusca);
  }, 500);

  render() {
    return (
      <Filtros
        filtros={this.props.filtros}
        handleAlterarFiltros={this.props.atualizarFitrosBuscaAcancada}
        handleClickFiltroAvancada={this.handleClickFiltroAvancada}
        statusAtividade={this.props.statusAtividade}
        tiposAtividade={this.props.tiposAtividade}
        handleClickStatusAtividade={this.handleClickStatusAtividade}
        handleClickTipoAtividade={this.handleClickTipoAtividade}
        campoBusca={this.state.campoBusca}
        handleChangeCampoBusca={this.handleChangeCampoBusca}
        modalBuscaAvancadaOpen={this.props.modalBuscaAvancadaOpen}
        modoBuscaAvancada={this.props.modoBuscaAvancada}
        isGerente={this.props.isGerente}
        funilId={this.props.funilId}
      />
    );
  }
}

FiltrosContainer.propTypes = {
  abrirModalBuscaAvancada: PropTypes.func,
  statusAtividade: PropTypes.array,
  tiposAtividade: PropTypes.array,
  handleChangeStatusAtividade: PropTypes.func,
  handleChangeCampoBuscaRedux: PropTypes.func,
  modalBuscaAvancadaOpen: PropTypes.bool,
  modoBuscaAvancada: PropTypes.bool,
  isGerente: PropTypes.bool,
  atualizarFitrosBuscaAcancada: PropTypes.func,
  filtros: PropTypes.object,
  campoBuscaRedux: PropTypes.string,
  funilId: PropTypes.number,
};

const mapStateToProps = state => ({
  statusAtividade: state.baseLeads.listagens.statusAtividade,
  modalBuscaAvancadaOpen: state.baseLeads.modals.buscaAvancada.open,
  modoBuscaAvancada: state.baseLeads.modoBuscaAvancada,
  isGerente: state.usuario.isGerente,
  filtros: state.baseLeads.modals.buscaAvancada.filtros,
  campoBuscaRedux: state.baseLeads.campoBusca,
});

const mapDispatchToProps = dispatch => ({
  atualizarFitrosBuscaAcancada: f => dispatch(handleAtualizarFitrosBuscaAcancada(f)),
  abrirModalBuscaAvancada: () => dispatch(abrirModalBuscaAvancada()),
  handleChangeStatusAtividade: idStatus => dispatch(handleChangeStatusAtividade(idStatus)),
  handleChangeCampoBuscaRedux: campoBusca => dispatch(handleChangeCampoBusca(campoBusca)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FiltrosContainer);
