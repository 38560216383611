import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Popover, Card } from "@material-ui/core";
import withStyles from "../../../../../_common/hocs/injectSheet";
import { closeFlyout } from "../actions";

const PopoverCard = ({ open, onClose, anchor, classes, children, ...props }) => (
  <Popover
    className={classes.flyout}
    open={open}
    onClose={onClose}
    anchorReference="anchorPosition"
    anchorPosition={{ top: anchor.top, left: anchor.left }}
    anchorOrigin={{ horizontal: "left", vertical: "top" }}
    {...props}
  >
    <Card className={classes.card} square>
      {children}
    </Card>
  </Popover>
);

const mapState = ({ flyout }) => ({ open: flyout.open, anchor: flyout.anchor });

PopoverCard.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  anchor: PropTypes.shape({
    top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  classes: PropTypes.object,
  children: PropTypes.node,
};

export default compose(
  withStyles({
    flyout: { zIndex: "1501 !important" },
    card: {
      width: 400,
      borderRadius: 0,
      margin: 0,
    },
  }),
  connect(
    mapState,
    { onClose: closeFlyout }
  )
)(PopoverCard);
