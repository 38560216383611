import React from "react";
import { useIntl, defineMessage } from "react-intl";
import PropTypes from "prop-types";
import { Tooltip } from "@material-ui/core";

import { useImpeditivos } from "../../../../../hooks";
import { ActionButton } from "../../../../../components";
import { createDialogConfirm, createSnackbar } from "../../../../../_common/";

DuplicarEtapa.propTypes = {
  etapa: PropTypes.object,
  onDuplicate: PropTypes.func,
  closeMenu: PropTypes.func,
  impeditivo: PropTypes.object,
};

const message = defineMessage({
  defaultMessage: "A etapa não pode ser duplicada, pois possui {operacoes} configurada(s)",
});

function DuplicarEtapa({ etapa, impeditivo, onDuplicate, closeMenu, ...props }) {
  const intl = useIntl();
  const operacoes = useImpeditivos(impeditivo?.impeditivos);

  function duplicar() {
    createDialogConfirm({
      title: intl.formatMessage({
        defaultMessage: "Duplicar etapa",
      }),

      text: intl.formatMessage({
        defaultMessage: "Uma cópia desta etapa será criada logo após esta.",
      }),

      tamanhoMaximo: "sm",

      callback: confirmed => {
        if (confirmed) {
          onDuplicate(true);
          closeMenu();
          createSnackbar(
            intl.formatMessage({
              defaultMessage: "Ação concluída com sucesso!",
            })
          );
        }
      },
    });
  }

  return (
    <Tooltip title={impeditivo?.impeditivos.length ? intl.formatMessage(message, { operacoes }) : ""}>
      <span>
        <ActionButton
          id="btn-duplicar-etapa"
          type="menu"
          disabled={!!impeditivo?.impeditivos.length}
          label={intl.formatMessage({ defaultMessage: "Duplicar Etapa" })}
          onClick={duplicar}
          {...props}
        />
      </span>
    </Tooltip>
  );
}

export default DuplicarEtapa;
