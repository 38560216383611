import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";

import { Flex } from "../../../../../_common";
import {
  EtapaNome,
  EtapaTotal,
  EtapaPosicao,
  EtapaTipoGate,
  EtapaQuestionario,
  EtapaFeedback,
  EtapaPermissoes,
} from "./Fields";
import { useTipoPlanoSpotter } from "../../../../../hooks";

Etapa.propTypes = {
  questionarios: PropTypes.array,
  etapa: PropTypes.object,
  etapas: PropTypes.array,
  impeditivos: PropTypes.array,
  classes: PropTypes.object,
  index: PropTypes.number,
  formName: PropTypes.string,
  changeOrdem: PropTypes.func,
  onAlterarGate: PropTypes.func,
};

const styles = {
  root: {
    height: "100%",
    flex: 1,
    flexBasis: "auto",
  },
  field: {
    marginBottom: 20,
  },
  permissionSubtitle: {
    lineHeight: "32px",
    minWidth: 300,
    fontFamily: "Roboto",
    fontSize: 12,
  },
  permissionContainer: { paddingTop: 10 },
  permissionField: { width: 128 },
};

function Etapa({
  etapa,
  questionarios,
  etapas,
  index,
  formName = "",
  classes,
  changeOrdem,
  impeditivos,
  onAlterarGate,
  ...props
}) {
  const novaEtapa = !etapa.id;
  const isSpotterFull = useTipoPlanoSpotter();

  return (
    <Flex flexDirection="column" className={classes.root} {...props}>
      <EtapaNome novaEtapa={novaEtapa} index={index} formName={formName} classes={classes} />

      {!novaEtapa && <EtapaTotal index={index} totalLeads={etapa.totalLeads} classes={classes} />}

      <EtapaPosicao
        novaEtapa={novaEtapa}
        changeOrdem={changeOrdem}
        impeditivos={impeditivos}
        formName={formName}
        index={index}
        etapas={etapas}
        classes={classes}
      />

      <EtapaTipoGate
        novaEtapa={novaEtapa}
        index={index}
        formName={formName}
        etapa={etapa}
        etapas={etapas}
        questionarios={questionarios}
        classes={classes}
        onAlterarGate={onAlterarGate}
      />

      <EtapaQuestionario
        novaEtapa={novaEtapa}
        index={index}
        formName={formName}
        etapa={etapa}
        etapas={etapas}
        questionarios={questionarios}
        classes={classes}
      />

      <EtapaFeedback
        index={index}
        formName={formName}
        etapa={etapa}
        etapas={etapas}
        questionarios={questionarios}
        classes={classes}
      />

      {isSpotterFull && (
        <EtapaPermissoes novaEtapa={novaEtapa} index={index} formName={formName} etapa={etapa} classes={classes} />
      )}
    </Flex>
  );
}

export default withStyles(styles)(Etapa);
