import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, DialogTitle, DialogContent, DialogActions, withStyles } from "@material-ui/core";
import useSWR from "swr";
import { Formik, Form } from "formik";
import { useIntl } from "react-intl";
import { TIPO_APLICACAO_QUESTIONARIO } from "../../../../../_common/constantes";
import Etapa from "../Etapa/";

ModalCriarEtapa.propTypes = {
  onClose: PropTypes.func,
  etapas: PropTypes.array,
  impeditivos: PropTypes.array,
};

const styles = () => ({
  root: {
    height: "100%",
    flex: 1,
    flexBasis: "auto",
  },
  field: {
    marginBottom: 20,
  },
  gateway: {
    width: 40,
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginRight: 10,
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    height: 20,
  },
  actions: {
    borderTop: "1px solid gray",
  },
});

const initialValues = {
  nome: "",
  ordem: 0,
  tipoGate: 1,
  totalLeads: 0,
  permissoes: [{ tipoPerfil: 6, permissao: 2 }, { tipoPerfil: 2, permissao: 2 }],
  questionario: { id: 0 },
  questionarioUsuarioId: 0,
  tipoAplicacaoQuestionario: TIPO_APLICACAO_QUESTIONARIO.PADRAO,
  regras: [{ questionarioId: "", regraId: "" }],
  possuiSelecaoGrupo: false,
  idsGrupo: [],
  permissaoGrupos: [],
};

function ModalCriarEtapa({ classes, etapas, impeditivos, onClose }) {
  const intl = useIntl();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: questionarios } = useSWR("/api/pipeline/questionario/listar");

  if (!etapas || !questionarios) return null;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={values => {
        setIsSubmitting(true);
        onClose({ ...values, id: `$VIRTUAL${Date.now()}_${Math.random()}` });
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <DialogTitle>{intl.formatMessage({ defaultMessage: "Criar etapa" })}</DialogTitle>
          <DialogContent>
            <Etapa
              questionarios={questionarios}
              impeditivos={impeditivos}
              etapa={values}
              etapas={etapas}
              index={values.ordem}
              changeOrdem={e => setFieldValue("ordem", e.target.value)}
            />
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button onClick={() => onClose()}>{intl.formatMessage({ defaultMessage: "Cancelar" })}</Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {intl.formatMessage({ defaultMessage: "Salvar" })}
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}

export default withStyles(styles, { withTheme: true })(ModalCriarEtapa);
