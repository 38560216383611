import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Switch } from "react-router";
import Loadable from "react-loadable";
import { Loading, ExactRoute } from "../../_common";
import routes from "../../_common/components/MainRoutes/constantes";

const LoadableHomeFunil = Loadable({
  loader: () => import(/* webpackChunkName: "HomeBaseLeadsFunil" */ "./pipeline"),
  loading: () => <Loading isLoading />,
});

const LoadableFunilList = Loadable({
  loader: () => import(/* webpackChunkName: "FunilList" */ "./funil/ListarFunil"),
  loading: () => <Loading isLoading />,
});

const LoadableFunilConfig = Loadable({
  loader: () => import(/* webpackChunkName: "FunilConfig" */ "./funil/EditarFunil"),
  loading: () => <Loading isLoading />,
});

const Routes = () => (
  <DndProvider backend={HTML5Backend}>
    <Switch>
      <ExactRoute
        path="/spotter/base-leads/funil"
        roles={routes.LEAD_OPORTUNIDADE_BASE_DE_LEADS_FUNIL.roles}
        exact
        component={LoadableHomeFunil}
      />
      <ExactRoute
        path="/spotter/base-leads/funil/listar"
        roles={routes.BASE_LEADS_FUNIL_LISTAR.roles}
        component={LoadableFunilList}
      />
      <ExactRoute
        path="/spotter/base-leads/funil/configurar/:funilId"
        roles={routes.BASE_LEADS_FUNIL_CONFIGURAR.roles}
        component={LoadableFunilConfig}
      />
    </Switch>
  </DndProvider>
);

export default Routes;
