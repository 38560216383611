import React from "react";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, DialogTitle, DialogContent, DialogActions, MenuItem } from "@material-ui/core";

ModalMoverEtapa.propTypes = {
  onClose: PropTypes.func,
  etapa: PropTypes.object,
  etapas: PropTypes.array,
};

export default function ModalMoverEtapa({ etapa, etapas, onClose }) {
  const intl = useIntl();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ordem: etapa.ordem,
      }}
      onSubmit={values => onClose(values.ordem)}
    >
      {({ values }) => (
        <Form>
          <DialogTitle>
            <FormattedMessage defaultMessage="Mover etapa" />
          </DialogTitle>

          <DialogContent>
            <Field
              component={TextField}
              fullWidth
              name="ordem"
              label={intl.formatMessage({ defaultMessage: "Local" })}
              id="posicao-etapa"
              select
            >
              <MenuItem key="inicio" value={1}>
                <FormattedMessage defaultMessage="Início" />
              </MenuItem>
              {etapas.map(
                ({ id, ordem, nome }) =>
                  ordem !== values.ordem && (
                    <MenuItem key={id} value={ordem + 1}>
                      {intl.formatMessage({ defaultMessage: "Após {etapa}" }, { etapa: nome })}
                    </MenuItem>
                  )
              )}
            </Field>
          </DialogContent>
          <DialogActions>
            <Button id="btn-cancelar-mover" onClick={() => onClose()}>
              <FormattedMessage defaultMessage="Cancelar" />
            </Button>
            <Button type="submit" id="btn-salvar-mover" color="primary">
              <FormattedMessage defaultMessage="Salvar" />
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}
