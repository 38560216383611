import { defineMessage } from "react-intl";

export const TOTAL_LEADS_POR_PAGINA = 20;

export const TIPO_FASE = {
  ENTRADA: 1,
  FILTRO: 2,
  AGENDADO: 3,
  QUALIFICADO: 4,
  VENDIDO: 5,
  DESCARTADO: 6,
};

export const COR_ATIVIDADE = {
  SEM_ATIVIDADE: "#41adcf",
  ATRASADA: "#ec4a2c",
  PARA_HOJE: "#f1be24",
  PROXIMOS_DIAS: "#5e5e5e",
};

export const STATUS_ATIVIDADE = {
  SEM_ATIVIDADE: 0,
  ATRASADA: 1,
  PARA_HOJE: 2,
  FUTURA: 3,
};

export const LISTA_STATUS_ATIVIDADE = [
  {
    id: STATUS_ATIVIDADE.ATRASADA,
    cor: COR_ATIVIDADE.ATRASADA,
    tooltip: defineMessage({ defaultMessage: "Atividades atrasadas" }),
    checked: true,
    idComponente: "filtroAtividadesAtrasadas",
  },
  {
    id: STATUS_ATIVIDADE.PARA_HOJE,
    cor: COR_ATIVIDADE.PARA_HOJE,
    tooltip: defineMessage({ defaultMessage: "Atividades para hoje" }),
    checked: true,
    idComponente: "filtroAtividadesHoje",
  },
  {
    id: STATUS_ATIVIDADE.FUTURA,
    cor: COR_ATIVIDADE.PROXIMOS_DIAS,
    tooltip: defineMessage({ defaultMessage: "Atividades para os próximos dias" }),
    checked: true,
    idComponente: "filtroAtividadesFuturas",
  },
  {
    id: STATUS_ATIVIDADE.SEM_ATIVIDADE,
    cor: COR_ATIVIDADE.SEM_ATIVIDADE,
    tooltip: defineMessage({ defaultMessage: "Lead sem atividades" }),
    checked: true,
    idComponente: "filtroSemAtividade",
  },
];
