import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { withTheme } from "react-jss";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { Paper, TextField } from "@material-ui/core";
import Search from "@material-ui/icons/Search";
import { useIntl } from "react-intl";

import ModalCancelarReuniao from "../ModalCancelarReuniao/ModalCancelarReuniao";
import ModalDescartarLead from "../../../../pages/detalhes-lead/Modal/ModalDescartarLead/ModalDescartarLead";

import { Flex, CorCheck, AjaxBlackout } from "../../../../_common";
import injectSheet from "../../../../_common/hocs/injectSheet/injectSheet";
import CadastrarLead from "../../../../pages/lead/components/cadastrar";
import { recarregarEntrada } from "../../pipeline/actions";
import BuscaPesquisa from "../../../../components/BuscaAvancada";
import { SelectTipoAtividade } from "../../../../components";
import { MenuFunil } from "../ListarFunil";

const styles = theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: [0, 24],
    height: 48,
    minHeight: 48,
    marginTop: 10,
    marginBottom: 24,
    borderRadius: 0,
  },
  buscaAvancada: {
    marginLeft: "auto",
  },
  icone20: {
    width: "20px !important",
    height: "20px !important",
  },
  iconeBuscaAvancada: {
    margin: "0 10px",
    cursor: "pointer",
    color: ({ modoBuscaAvancada }) =>
      modoBuscaAvancada ? theme.palette.accent1Color : theme.palette.icons.darkActiveIcon,
    "&:hover": {
      color: theme.palette.icons.darkFocusedIcon,
    },
  },
  campoBusca: {
    margin: "0 10px",
  },
  iconeCampoBusca: {
    margin: "0 10px",
  },
  filtroTipoAtividade: {
    paddingLeft: 24,
  },
});

const Filtros = ({
  handleAlterarFiltros,
  statusAtividade,
  handleClickStatusAtividade,
  campoBusca,
  handleChangeCampoBusca,
  isGerente,
  classes,
  theme,
  funilId,
}) => {
  const dispatch = useDispatch();
  const afterCreate = id => {
    AjaxBlackout.Hide();
    if (id) dispatch(recarregarEntrada());
  };
  const intl = useIntl();

  const onSave = () => window.location.reload();

  return (
    <React.Fragment>
      <Paper id="filtrosBaseLeads" className={classes.root}>
        {statusAtividade.map(status => (
          <CorCheck
            key={status.id}
            id={status.id}
            cor={status.cor}
            tooltip={intl.formatMessage(status.tooltip)}
            checked={status.checked}
            handleOnClick={handleClickStatusAtividade}
            name={status.idComponente}
          />
        ))}

        <SelectTipoAtividade onChange={tipoAtividades => handleAlterarFiltros({ tipoAtividades })} hideLabel />

        <Flex alignItems="center" className={classes.buscaAvancada}>
          <Flex alignItems="center">
            <Search
              nativeColor={theme.palette.icons.darkActiveIcon}
              className={cn(classes.iconeCampoBusca, classes.icone20)}
            />
            <TextField
              id="campoBuscaLead"
              className={classes.campoBusca}
              placeholder={intl.formatMessage({ defaultMessage: "Buscar lead" })}
              onChange={handleChangeCampoBusca}
              value={campoBusca}
            />
          </Flex>

          <BuscaPesquisa onChange={handleAlterarFiltros} />

          {!isGerente ? (
            <CadastrarLead callback={afterCreate} />
          ) : (
            <MenuFunil callback={afterCreate} onSave={onSave} funilId={funilId} />
          )}
        </Flex>
      </Paper>

      <ModalCancelarReuniao />
      <ModalDescartarLead />
    </React.Fragment>
  );
};

Filtros.propTypes = {
  handleAlterarFiltros: PropTypes.func,
  statusAtividade: PropTypes.array,
  handleClickStatusAtividade: PropTypes.func,
  campoBusca: PropTypes.string,
  handleChangeCampoBusca: PropTypes.func,
  isGerente: PropTypes.bool,
  theme: PropTypes.object,
  classes: PropTypes.object,
  funilId: PropTypes.number,
};

const enhance = compose(
  withTheme,
  injectSheet(styles)
);

export default enhance(Filtros);
