import React from "react";
import PropTypes from "prop-types";
import { IconButton, withStyles, Tooltip } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";

const Botao = ({ icone, classes, tooltip, link, ...props }) => (
  <Tooltip title={tooltip} disableFocusListener>
    <IconButton href={link} size="small" className={classes.botao} {...props}>
      <Icon className={`material-icons ${classes.icone}`}>{icone}</Icon>
    </IconButton>
  </Tooltip>
);

Botao.propTypes = {
  icone: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  link: PropTypes.string,
  classes: PropTypes.object,
};

export default withStyles({
  botao: {
    width: 32,
    "&:hover": { background: "transparent" },
  },
  icone: {
    height: 20,
    width: 20,
    margin: "auto",
    fontSize: 20,
  },
})(Botao);
