import React, { useState } from "react";
import { useIntl, defineMessage } from "react-intl";
import { Dialog, Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";

import { useImpeditivos } from "../../../../../hooks";
import { ActionButton } from "../../../../../components";
import ModalMoverEtapa from "./ModalMoverEtapa";

MoverEtapa.propTypes = {
  etapa: PropTypes.object,
  etapas: PropTypes.array,
  closeMenu: PropTypes.func,
  onMove: PropTypes.func,
};

const message = defineMessage({
  defaultMessage: "A etapa não pode ser movida, pois possui {operacoes} configurada(s)",
});

function MoverEtapa({ etapa, etapas, onMove, closeMenu, ...props }) {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const operacoes = useImpeditivos(etapa.impeditivos);

  const toggle = () => setOpen(!open);

  function onClose(ordem) {
    if (ordem) onMove(ordem);
    toggle();
    closeMenu();
  }

  return (
    <>
      <Tooltip title={etapa.impeditivos.length ? intl.formatMessage(message, { operacoes }) : ""}>
        <span>
          <ActionButton
            id="btn-mover-etapa"
            type="menu"
            disabled={!!etapa.totalLeads || !!etapa.impeditivos.length}
            label={intl.formatMessage({ defaultMessage: "Mover etapa" })}
            onClick={() => toggle()}
            {...props}
          />
        </span>
      </Tooltip>

      <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
        <ModalMoverEtapa etapa={etapa} etapas={etapas} onClose={onClose} />
      </Dialog>
    </>
  );
}

export default MoverEtapa;
