import React from "react";
import PropTypes from "prop-types";
import { withTheme } from "react-jss";
import { TIPO_GATE } from "../../../../../_common/constantes";
import { AgendarReuniao, IniciarFiltro } from "../../../../lead/components";
import DesfazerPularEtapas from "../../../../lead/components/DesfazerPularEtapas";
import PassarManualmente from "../../../../lead/components/PassarManualmente";
import MudarDeFunil from "../../../../lead/components/MudarDeFunil";
import DesfazerTransferirDeFunil from "../../../../lead/components/DesfazerTransferirDeFunil";
import { useTipoPlanoSpotter, useUsuario } from "../../../../../hooks";

function CardLeadActions({ lead, etapa, handleMoveLead, classes }) {
  const { moverleadlivrementeentrefunis } = useUsuario();
  const isSpotterFull = useTipoPlanoSpotter();

  return (
    <div className={classes.containerAcoes}>
      <div className={classes.containerAcoesPadroes}>
        {etapa.gate.tipoGate === TIPO_GATE.FILTRO && <IniciarFiltro lead={lead} style={{ padding: 0 }} />}

        {(etapa.gateAnterior === TIPO_GATE.MANUAL || (!etapa.ultima && etapa.tipoGate === TIPO_GATE.MANUAL)) && (
          <PassarManualmente
            type="icon"
            lead={lead}
            etapaLead={etapa}
            style={{ padding: 0 }}
            onTransfer={etapaDestino => (etapaDestino ? handleMoveLead(lead.etapa.id, +etapaDestino) : null)}
          />
        )}

        <AgendarReuniao lead={lead} style={{ padding: 0, paddingBottom: 4 }} redirecionarBaseLeads />

        <AgendarReuniao lead={lead} style={{ padding: 0, paddingBottom: 4 }} reagendar redirecionarBaseLeads />

        <DesfazerPularEtapas
          callback={(success, original, current) => success && handleMoveLead(original, current)}
          lead={lead}
          style={{ padding: 0, paddingBottom: 4 }}
        />

        <DesfazerTransferirDeFunil
          callback={(success, original, current) => success && handleMoveLead(original, current)}
          lead={lead}
          style={{ padding: 0, paddingBottom: 4 }}
        />

        {(etapa.ultima || moverleadlivrementeentrefunis) &&
          isSpotterFull && (
            <MudarDeFunil
              moverLivremente={moverleadlivrementeentrefunis}
              type="icon"
              lead={lead}
              etapaLead={etapa}
              style={{ padding: 0 }}
              onTransfer={etapaDestino => (!etapaDestino ? handleMoveLead(lead.etapa.id, +etapaDestino) : null)}
            />
          )}
      </div>
    </div>
  );
}
CardLeadActions.propTypes = {
  lead: PropTypes.object,
  etapa: PropTypes.object,
  handleMoveLead: PropTypes.func,
  classes: PropTypes.object,
};

export default withTheme(CardLeadActions);
