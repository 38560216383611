import React from "react";
import PropTypes from "prop-types";
import { TextField } from "formik-material-ui";
import { MenuItem } from "@material-ui/core";
import { Field } from "formik";

import { useIntl } from "react-intl";

import { TIPO_QUESTIONARIO, TIPO_GATE } from "../../../../../../_common/constantes";

EtapaFeedback.propTypes = {
  index: PropTypes.number,
  formName: PropTypes.string,
  etapa: PropTypes.object,
  etapas: PropTypes.array,
  questionarios: PropTypes.array,
  classes: PropTypes.object,
};

function EtapaFeedback({ index, formName, etapa, etapas, questionarios, classes }) {
  const intl = useIntl();

  const isFeedback = React.useMemo(
    () =>
      questionarios.find(questionario => questionario.id === etapa.questionario.id)?.tipo ===
      TIPO_QUESTIONARIO.FEEDBACK,
    [questionarios, etapa]
  );

  return (
    <>
      {etapa.tipoGate === TIPO_GATE.FILTRO &&
        etapas[index - 1]?.tipoGate === TIPO_GATE.AGENDAMENTO &&
        isFeedback && (
          <Field
            className={classes.field}
            component={TextField}
            select
            name={`${formName}questionarioUsuarioId`}
            id={`questionario-usuario-etapa_${index}`}
            label={intl.formatMessage({ defaultMessage: "Avaliação" })}
            InputLabelProps={etapa.questionario?.id ? { shrink: true } : null}
            disabled={!questionarios.length}
          >
            {questionarios
              .filter(questionario => questionario.tipo === TIPO_QUESTIONARIO.AVALIACAO_USUARIO)
              .map(({ id, descricao }) => (
                <MenuItem id={`menu-item-questionarioUsuarioId_${id}`} key={id} value={id}>
                  {descricao}
                </MenuItem>
              ))}
          </Field>
        )}
    </>
  );
}

export default EtapaFeedback;
