import React from "react";
import { defineMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import useSWR from "swr";
import { useHistory } from "react-router";
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  withStyles,
  Paper,
  Button,
} from "@material-ui/core";
import { move } from "formik";
import axios from "axios";

import { Subheader } from "../../../../components";
import { FilterText, Flex, ListagemSemRegistro, Loading, createSnackbar, AjaxBlackout } from "../../../../_common";
import Row from "./Row";
import { messages, FunisAPI } from "./constants";
import { usePageTitle, useTipoPlanoSpotter } from "../../../../hooks";
import { removeDiacritics } from "../../../../_common/utils/string";
import { CriarFunil } from "../../funil/EditarFunil";
import FeedbackRollout from "../../../../components/FeedbackRollout";
import routes from "../../../../_common/components/MainRoutes/constantes";

const TABS = {
  "/spotter/base-leads/funil/listar": {
    entity: "lead",
    value: "/spotter/base-leads/funil/listar",
    label: defineMessage({ defaultMessage: "Configurar" }),
  },
};

function ListarFunis({ classes }) {
  const intl = useIntl();
  const history = useHistory();
  const currentTab = TABS[history.location.pathname];
  const endpoints = new FunisAPI();
  const isSpotterFull = useTipoPlanoSpotter();
  const { data: funisTemplate } = useSWR(endpoints.listarTemplates);
  const { data: funis, mutate, error } = useSWR(endpoints.listar());
  const isLoading = !funis && !error;
  const [searchTerm, setSearchTerm] = React.useState("");
  const [camposFiltrados, setCamposFiltrados] = React.useState("");
  const [inativarReordenacao, setInativarReordenacao] = React.useState(false);
  const funilTemplate = funisTemplate ? funisTemplate[0] : null;

  if (!isSpotterFull) {
    createSnackbar(
      intl.formatMessage({
        defaultMessage: "Sua conta não tem acesso a esta funcionalidade. Você será redirecionado.",
      }),
      2800
    );
    setTimeout(() => {
      history.push(`${routes.LEAD_OPORTUNIDADE_BASE_DE_LEADS_FUNIL.route}`);
    }, 3000);
  }

  usePageTitle(intl.formatMessage({ defaultMessage: "Lista de Funis - Exact Sales" }));
  const onSave = () => window.location.reload();

  React.useEffect(
    () => {
      let filtrados = [];
      const original = Array.isArray(funis) ? funis : [];

      filtrados = searchTerm
        ? original.filter(c =>
            removeDiacritics(c.nome.toLowerCase()).includes(removeDiacritics(searchTerm.toLowerCase()))
          )
        : original;

      setCamposFiltrados(filtrados);
      if (searchTerm || filtrados.length === 1) setInativarReordenacao(true);
      else setInativarReordenacao(false);
    },
    [searchTerm, funis]
  );

  const onMove = (item, funilItem) => {
    try {
      AjaxBlackout.Show();
      const listaPosicional = [];
      funis.forEach((element, i) => {
        listaPosicional.push({ ...element, posicao: element.posicao ?? i + 1 });
      });
      const origem = listaPosicional.find(x => x.id === item.id);
      const destino = listaPosicional.find(x => x.id === funilItem.id);
      const ordenados = listaPosicional.filter(a => !!a.nome).sort((a, b) => a.posicao - b.posicao);
      const movido = move(ordenados, origem.posicao - 1, destino.posicao - 1);
      const alterado = movido.filter(Boolean).map((d, i) => ({ ...d, posicao: i + 1 }));

      axios.post("/api/pipeline/funil/reordenar", alterado).then(resp => {
        if (resp) mutate();
        else createSnackbar(intl.formatMessage({ defaultMessage: "Não foi possível reordenar a lista de funis" }));
      });
    } catch (err) {
      createSnackbar(intl.formatMessage({ defaultMessage: "Não foi possível reordenar a lista de funis" }));
    } finally {
      AjaxBlackout.Hide();
    }
  };

  return (
    <Loading isLoading={!isSpotterFull}>
      <Subheader
        title={intl.formatMessage(messages.listafunis)}
        breadcrumb={[
          { titulo: intl.formatMessage(messages.funil), url: "/spotter/base-leads/funil" },
          { titulo: intl.formatMessage(messages.listafunis) },
        ]}
      >
        <Tabs value={currentTab.value} onChange={(e, value) => history.push(value)}>
          {Object.values(TABS).map(tab => (
            <Tab label={intl.formatMessage(tab.label)} value={tab.value} key={tab.value} />
          ))}
        </Tabs>

        <FeedbackRollout classe={"feedback-mfs"} />
        <CriarFunil onSave={onSave} funilId={funilTemplate?.id} />
      </Subheader>

      <FilterText
        value={searchTerm}
        onChange={({ target }) => setSearchTerm(target.value)}
        placeholder={intl.formatMessage({ defaultMessage: "Buscar funil..." })}
        disabled={isLoading}
        onCancelSearch={() => setSearchTerm("")}
      />

      <Paper className={classes.paper}>
        <Loading isLoading={isLoading}>
          {camposFiltrados?.length > 0 && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "10%" }} align="center" />
                  <TableCell style={{ width: "15%" }}>{intl.formatMessage(messages.nome)}</TableCell>
                  <TableCell style={{ width: "10%" }} align="center">
                    {intl.formatMessage(messages.ativo)}
                  </TableCell>
                  <TableCell style={{ width: "10%" }} align="center">
                    {intl.formatMessage(messages.padrao)}
                  </TableCell>
                  <TableCell style={{ width: "10%" }} align="center" />
                </TableRow>
              </TableHead>
              <TableBody>
                {camposFiltrados.map(campo => (
                  <Row
                    key={campo.id}
                    funil={campo}
                    onClickEdit={() => setSearchTerm(campo.id)}
                    onMove={onMove}
                    inativarOrdenacao={inativarReordenacao}
                  />
                ))}
              </TableBody>
            </Table>
          )}
          {!searchTerm &&
            !camposFiltrados?.length && (
              <Flex center>
                <ListagemSemRegistro
                  icone="description"
                  mensagem={intl.formatMessage({
                    defaultMessage: "Nenhum funil cadastrado até o momento.",
                  })}
                  botoes={<CriarFunil onSave={onSave} funilId={funilTemplate?.id} />}
                />
              </Flex>
            )}
          {!!searchTerm &&
            !camposFiltrados?.length && (
              <Flex center>
                <ListagemSemRegistro
                  icone="description"
                  mensagem={intl.formatMessage(
                    { defaultMessage: "Nenhum campo encontrado com o termo <b>{searchTerm}</b>" },
                    { searchTerm }
                  )}
                  botoes={
                    <Button color="primary" onClick={() => setSearchTerm("")}>
                      {intl.formatMessage({ defaultMessage: "Limpar filtro" })}
                    </Button>
                  }
                />
              </Flex>
            )}
        </Loading>
      </Paper>
    </Loading>
  );
}

ListarFunis.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(theme => ({
  paper: {
    margin: theme.spacing.unit * 3,
  },
  card: {
    margin: 24,
    paddingBottom: 56,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  cardContent: {
    padding: 0,
    border: `1px solid ${theme.palette.borderColor}`,
    borderRadius: "10px",
    width: 890,
  },
  header: { textAlign: "center" },
}))(ListarFunis);
