import actions from "./actions";
import { createReducer } from "../../../../_common/utils/redux";

const initialState = {
  anchor: { top: 0, left: 0 },
  open: false,
  leadId: undefined,
  etapaId: undefined,
  etapa: {},
  lead: undefined,
  preVendedorId: undefined,
  gate: undefined,
  gateAnterior: undefined,
  vendaAberta: false,
  dialogo: {
    open: false,
    titulo: "",
    texto: "",
    acao: () => null,
    labelCancelar: "",
    labelConfirmar: "",
    onSucesso: null,
    onErro: null,
  },
};

export default createReducer(initialState, {
  [actions.BUSCAR_LEAD]: (state, { lead }) => ({ ...state, lead }),
  [actions.ABRIR_FLYOUT_DETALHE_LEAD]: (
    state,
    { anchor, args: { gate, gateAnterior, leadId, preVendedorId, etapaId, etapa } }
  ) => ({
    ...state,
    anchor,
    gate,
    gateAnterior,
    leadId,
    etapaId,
    etapa,
    preVendedorId,
    open: true,
    lead: leadId !== state.leadId ? undefined : state.lead,
  }),
  [actions.FECHAR_FLYOUT_DETALHE_LEAD]: state => ({ ...state, open: false, leadId: undefined }),
  [actions.ABRIR_DIALOGO_CONFIRMACAO]: (state, payload) => ({
    ...state,
    dialogo: { open: true, labelCancelar: "Cancelar", ...payload },
  }),
  [actions.FECHAR_DIALOGO_CONFIRMACAO]: state => ({
    ...state,
    dialogo: { open: false, acao: () => undefined },
  }),
  [actions.TOGGLE_VENDA]: (state, payload) => ({ ...state, vendaAberta: payload }),
});
