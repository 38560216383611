import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles, Popover, Paper, Divider, Typography } from "@material-ui/core";
import Help from "@material-ui/icons/Help";
import { compose } from "recompose";
import { injectIntl } from "react-intl";

import { Tipografia, Flex } from "../../../../../_common";

class FlyoutFunil extends Component {
  state = {
    anchor: null,
    open: false,
  };

  closeHistorico = () => this.setState({ anchor: null, open: false });
  openHistorico = anchor => {
    this.setState(() => ({
      anchor,
      open: true,
    }));
  };

  render() {
    const { nome, descricao, historico, data, leadId, classes, intl, funilAtual } = this.props;
    return (
      <Fragment>
        <Flex className={classes.root} flexDirection="column">
          <Tipografia cor="darkSecondaryText" tipo="notaLegendas">
            {intl.formatMessage({ defaultMessage: "Funil" })} | {intl.formatMessage({ defaultMessage: "Etapa" })}
            <Help
              className={classes.icon}
              id="flyout-funil-abrir-historico"
              onClick={evt => this.openHistorico(evt.currentTarget)}
            />
          </Tipografia>
          <Flex justifyContent="space-between" alignItems="flex-start">
            <Tipografia id={`flyout_funil_${leadId}`} cor="darkPrimaryText">
              {funilAtual} | {nome}
            </Tipografia>
            <Tipografia cor="darkPrimaryText">{descricao}</Tipografia>
          </Flex>
        </Flex>
        <Popover
          open={this.state.open}
          anchorEl={this.state.anchor}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          className={classes.popover}
          onClose={() => this.closeHistorico()}
          PaperProps={{ style: { backgroundColor: "transparent", marginTop: 16 } }}
        >
          <Paper square style={{ boxShadow: "20px 20px black" }}>
            <div className={classes.paper}>
              {historico.map(({ etapa, complemento }) => (
                <Typography key={etapa} style={{ lineHeight: "24px", fontSize: 12 }}>
                  <strong>{etapa}:</strong> {complemento}
                </Typography>
              ))}
            </div>
            <Divider />
            <Typography style={{ lineHeight: "32px", padding: "2px 4px" }}>{data}</Typography>
          </Paper>
        </Popover>
      </Fragment>
    );
  }
}

FlyoutFunil.propTypes = {
  nome: PropTypes.string.isRequired,
  funilAtual: PropTypes.string,
  descricao: PropTypes.string,
  historico: PropTypes.array.isRequired,
  data: PropTypes.string.isRequired,
  leadId: PropTypes.string,
  classes: PropTypes.object,
  intl: PropTypes.object,
};

const enhance = compose(
  withStyles({
    root: {
      minHeight: 40,
      paddingBottom: 16,
    },
    icon: {
      fontSize: 14,
      marginLeft: 6,
      marginRight: 8,
    },
    popover: {
      zIndex: "1601 !important",
    },
    paper: {
      padding: "12px 8px",
    },
  }),
  injectIntl
);

export default enhance(FlyoutFunil);
