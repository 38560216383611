import React from "react";
import PropTypes from "prop-types";
import { TextField } from "formik-material-ui";
import { MenuItem } from "@material-ui/core";
import { FastField } from "formik";
import { useIntl } from "react-intl";

import { LABELS_TIPO_PERFIL, PERMISSAO_ETAPA, LABELS_PERMISSAO_ETAPA } from "../../../../../../_common/constantes";

EtapaPermissoesPerfil.propTypes = {
  novaEtapa: PropTypes.bool,
  index: PropTypes.number,
  formName: PropTypes.string,
  etapa: PropTypes.object,
  classes: PropTypes.object,
};

const permissoes = [PERMISSAO_ETAPA.INTERAGIR, PERMISSAO_ETAPA.VISUALIZAR, PERMISSAO_ETAPA.OCULTAR];

function EtapaPermissoesPerfil({ novaEtapa, index, formName, etapa, classes }) {
  const intl = useIntl();

  return (
    <>
      {etapa?.permissoes?.map((perfil, i) => (
        <FastField
          key={perfil.tipoPerfil}
          className={classes.field}
          component={TextField}
          name={`${formName}permissoes[${i}].permissao`}
          label={intl.formatMessage(LABELS_TIPO_PERFIL[perfil.tipoPerfil])}
          id={novaEtapa ? `posicao-${perfil}-criar-etapa` : `permissao-${perfil.tipoPerfil}_${index}`}
          select
        >
          {permissoes.map(permissao => (
            <MenuItem
              id={novaEtapa ? `menu-item-criar-etapa-permissoes_${permissao}` : `menu-item-permissoes_${permissao}`}
              key={permissao}
              value={permissao}
            >
              {intl.formatMessage(LABELS_PERMISSAO_ETAPA[permissao])}
            </MenuItem>
          ))}
        </FastField>
      ))}
    </>
  );
}

export default EtapaPermissoesPerfil;
