import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import { Button, MenuItem, Menu } from "@material-ui/core";
import MoreVert from "@material-ui/icons/MoreVert";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import CadastrarLead from "../../../lead/components/cadastrar";

import { Flex } from "../../../../_common";
import { useTipoPlanoSpotter } from "../../../../hooks";

function MenuFunil({ callback, funilId }) {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const menuAnchorRef = useRef();
  const isSpotterFull = useTipoPlanoSpotter();

  return (
    <Flex alignItems="center">
      <Button id="open-menuFunil" onClick={() => setOpen(true)} buttonRef={menuAnchorRef}>
        <MoreVert />
      </Button>

      <Menu keepMounted open={open} anchorEl={menuAnchorRef.current} onClose={() => setOpen(false)}>
        <CadastrarLead exibirIcon callback={callback} />

        <MenuItem component={Link} id="menuItem-editarFunil" to={`/spotter/base-leads/funil/configurar/${funilId}`}>
          {intl.formatMessage({ defaultMessage: "Editar Funil" })}
        </MenuItem>
        {isSpotterFull && (
          <MenuItem component={Link} id="menuItem-listarFunis" to={"/spotter/base-leads/funil/listar"}>
            {intl.formatMessage({ defaultMessage: "Lista de funis" })}
          </MenuItem>
        )}
      </Menu>
    </Flex>
  );
}

MenuFunil.propTypes = {
  callback: PropTypes.func,
  funilId: PropTypes.number,
};

export default MenuFunil;
