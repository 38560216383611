import React from "react";
import PropTypes from "prop-types";

import Dialog from "material-ui/Dialog";

const customContentStyle = {
  width: 450,
  maxWidth: "none",
};

const ModalSelecionaTipoLista = ({ open, actions, handleOnRequestClose }) => (
  <Dialog
    title="Deseja cancelar a reunião?"
    open={open}
    actions={actions}
    onRequestClose={handleOnRequestClose}
    contentStyle={customContentStyle}
  />
);

ModalSelecionaTipoLista.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOnRequestClose: PropTypes.func.isRequired,
  actions: PropTypes.array.isRequired,
};

export default ModalSelecionaTipoLista;
