import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { IconButton } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import Avatar from "@material-ui/core/Avatar";
import DeleteIcon from "@material-ui/icons/Delete";
import { useIntl } from "react-intl";

import { excluirAtividade } from "../actions";
import withStyles from "../../../../../_common/hocs/injectSheet";
import Tipografia from "../../../../../_common/components/Tipografia";
import Flex from "../../../../../_common/components/Flex";
import { ConcluirAtividade, EditarAtividade } from "../../../../atividade/components";

const FlyoutAtividades = ({
  atividade: { id, tipo, data, icone, automacaoConfigEventoId, automacaoAcaoAtividadeId },
  lead,
  classes,
  excluir,
  etapa,
  callback,
}) => {
  const intl = useIntl();
  return (
    <Fragment>
      <Tipografia tipo="notaLegendas" cor="darkSecondaryText">
        {intl.formatMessage({ defaultMessage: "Atividade agendada" })}
      </Tipografia>
      <Flex className={classes.root}>
        <Avatar className={classes.avatar}>
          <Icon className={`material-icons ${classes.icone}`}>{icone}</Icon>
        </Avatar>
        <Flex justifyContent="space-between" className={classes.acoes}>
          <Flex flexDirection="column" className={classes.textos}>
            <Tipografia cor="darkPrimaryText">{tipo}</Tipografia>
            <Tipografia cor="darkSecondaryText" tipo="notaLegendas">
              {data}
            </Tipografia>
          </Flex>

          <Flex className={classes.botoes}>
            <Tipografia cor="darkSecondaryText">
              <IconButton tooltip="Excluir" id="flyout-atividade-excluir" onClick={() => excluir(id, etapa && etapa)}>
                <DeleteIcon />
              </IconButton>
              <EditarAtividade id={id} callback={callback} />
              <ConcluirAtividade
                id="flyout-atividade-concluir"
                atividade={{
                  id,
                  lead,
                  automacaoConfigEventoId,
                  automacaoAcaoAtividadeId,
                }}
                callback={callback}
                callbackConcluir={callback}
              />
            </Tipografia>
          </Flex>
        </Flex>
      </Flex>
    </Fragment>
  );
};

FlyoutAtividades.propTypes = {
  atividade: PropTypes.object,
  lead: PropTypes.object,
  classes: PropTypes.object,
  excluir: PropTypes.func,
  etapa: PropTypes.object,
  callback: PropTypes.func,
};

const styles = {
  root: {
    "&:hover $botoes": {
      visibility: "unset",
    },
    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
    marginBottom: 12,
    marginLeft: -24,
    marginRight: -24,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 8,
  },
  icone: {
    // FIXME tirar esses !important que hoje parecem necessários
    // porque o material está mudando a ordem das classes na build
    color: "white !important",
    height: "32px !important",
    width: "32px !important",
    textAlign: "center",
    lineHeight: "32px",
    fontSize: "18px !important",
  },
  acoes: {
    width: "100%",
  },
  textos: {
    maxWidth: 176,
  },
  avatar: {
    backgroundColor: "#797979 !important",
    height: "32px !important",
    width: "32px !important",
    marginBottom: 8,
    marginRight: 8,
  },
  botoes: {
    visibility: "hidden",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
};

export default compose(
  withStyles(styles),
  connect(
    undefined,
    {
      excluir: excluirAtividade,
    }
  )
)(FlyoutAtividades);
