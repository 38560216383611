import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";

import { FlatButton } from "material-ui";

import { objectKeysToCamelCase } from "../../../../_common/utils/string/string";

import ModalCancelarReuniao from "./ModalCancelarReuniao.jsx";
import createSnackBar from "../../../../_common/utils/snackbar/createSnackbar";

import { fecharModalCancelarReuniao, cancelarReuniao } from "../../../../store/reducers/baseLeads/baseLeadsActions";
import { AjaxBlackout } from "../../../../_common/index";

class ModalCancelarReuniaoContainer extends React.Component {
  actions = [
    <FlatButton key="btnNao" label="NÃO" onClick={this.props.fecharModalCancelarReuniao} />,
    <FlatButton key="btnSim" label="SIM" primary onClick={() => this.handleCancelarReuniao()} />,
  ];
  handleOnRequestClose = () => {
    this.props.fecharModalCancelarReuniao();
  };

  handleCancelarReuniao = async () => {
    AjaxBlackout.Show();
    const endpont = `/${this.props.isGerente ? "Gerencia" : "PreVendedor"}/BaseLeads/SetVisitaCancelada?pLeadId=${
      this.props.leadId
    }`;

    const respCancelamento = await axios.post(endpont);

    if (respCancelamento.data.Sucess) {
      const respNovoCard = await axios.get("/NovaBaseLeads/GetCardReuniaoCancelada", {
        params: { leadId: this.props.leadId },
      });

      const cardLead = objectKeysToCamelCase(respNovoCard.data);

      this.props.cancelarReuniao(cardLead);
      this.props.fecharModalCancelarReuniao();
      createSnackBar("Operação efetuada com sucesso!");
    }

    AjaxBlackout.Hide();
  };

  render() {
    return (
      <ModalCancelarReuniao
        open={this.props.open}
        handleOnRequestClose={this.handleOnRequestClose}
        actions={this.actions}
      />
    );
  }
}

ModalCancelarReuniaoContainer.propTypes = {
  open: PropTypes.bool,
  fecharModalCancelarReuniao: PropTypes.func,
  cancelarReuniao: PropTypes.func,
  isGerente: PropTypes.bool,
  leadId: PropTypes.number,
};

const mapStateToProps = state => ({
  open: state.baseLeads.modals.cancelarReuniao.open,
  leadId: state.baseLeads.modals.cancelarReuniao.leadId,
  isGerente: state.usuario.isGerente,
});

const mapDispatchToProps = dispatch => ({
  fecharModalCancelarReuniao: () => dispatch(fecharModalCancelarReuniao()),
  cancelarReuniao: cardLead => dispatch(cancelarReuniao(cardLead)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalCancelarReuniaoContainer);
