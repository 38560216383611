import React from "react";
import PropTypes from "prop-types";
import { TextField } from "formik-material-ui";
import { FastField } from "formik";

import { defineMessages, useIntl } from "react-intl";

EtapaNome.propTypes = {
  novaEtapa: PropTypes.bool,
  index: PropTypes.number,
  formName: PropTypes.string,
  classes: PropTypes.object,
};

const errorMessages = defineMessages({
  obrigatorio: { defaultMessage: "Campo obrigatório *" },
  muitoLongo: { defaultMessage: "O nome deve possuir 50 ou menos caracteres." },
});

function EtapaNome({ novaEtapa, index, formName, classes }) {
  const intl = useIntl();
  const CAMPO_OBRIGATORIO = intl.formatMessage(errorMessages.obrigatorio);
  const MUITO_LONGO = intl.formatMessage(errorMessages.muitoLongo);

  function validate(value) {
    if (!value) return CAMPO_OBRIGATORIO;
    if (value?.length > 50) return MUITO_LONGO;
    return undefined;
  }

  return (
    <FastField
      autoFocus
      className={classes.field}
      validate={validate}
      name={`${formName}nome`}
      component={TextField}
      id={`nome-etapa_${index}`}
      label={`${intl.formatMessage({ defaultMessage: "Nome da etapa" })}${novaEtapa ? " *" : ""}`}
    />
  );
}

export default EtapaNome;
