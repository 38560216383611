import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Typography, IconButton } from "@material-ui/core";
import FileCopyOutlined from "@material-ui/icons/FileCopyOutlined";
import PhoneForwarded from "@material-ui/icons/PhoneForwarded";
import MailOutlined from "@material-ui/icons/MailOutlined";
import Email from "@material-ui/icons/Email";
import Phone from "@material-ui/icons/Phone";
import useSWR from "swr";
import { useIntl } from "react-intl";

import withStyles from "../../../../../_common/hocs/injectSheet";
import { Flex, Tipografia } from "../../../../../_common";
import { copyToClipboardFromModal, formataLinkTelefone } from "../../../../../_common/utils/string";
import { formatPhoneMask } from "../../../../../_common/utils/phone";
import createSnackBar from "../../../../../_common/utils/snackbar/createSnackbar";
import { isAutenticado } from "../../../../../store/reducers/emails/emails";
import { sendEmail } from "../actions";
import { FlagDDI } from "../../../../lead/components/Telefones";

const FlyoutContato = ({
  nome,
  email,
  ddi,
  fone,
  callback,
  enviarEmail,
  leadId,
  podeEnviarEmail,
  lead,
  cargo,
  classes,
  setModalLigacaoIsOpen,
}) => {
  const intl = useIntl();

  const copiar = valor => {
    copyToClipboardFromModal(valor, "flyoutContato");
    createSnackBar(intl.formatMessage({ defaultMessage: "Copiado" }));
  };

  const { data: config } = useSWR("/api/pipeline/lead/ConfiguracoesCadastro");
  const customLinkTelefone = config?.customLinkTelefone;

  return (
    <Flex className={classes.root} flexDirection="column" justifyContent="center" id="flyoutContato">
      <Tipografia cor="darkSecondaryText" tipo="notaLegendas">
        {intl.formatMessage({ defaultMessage: "Contato principal" })}
      </Tipografia>
      <Typography>{nome}</Typography>
      {!!fone && (
        <Flex justifyContent="space-between" alignItems="center" className={classes.fone}>
          <Flex alignItems="center">
            <Phone color="primary" className={classes.icone} />
            <FlagDDI ddi={ddi} className={classes.flag} />
            {customLinkTelefone ? (
              <a href={formataLinkTelefone(`${ddi}${fone}`, customLinkTelefone)}>
                <Typography noWrap color="primary" variant="caption">
                  {formatPhoneMask({ ddi, phone: fone })}
                </Typography>
              </a>
            ) : (
              <Typography noWrap color="primary" variant="caption">
                {formatPhoneMask({ ddi, phone: fone })}
              </Typography>
            )}
          </Flex>
          <Flex className={classes.botoesFone}>
            <IconButton
              className={classes.botao}
              id="flyout-contato-abrir-ligacoes"
              onClick={() => {
                setModalLigacaoIsOpen(true);
                callback();
              }}
            >
              <PhoneForwarded className={classes.icone} color="primary" />
            </IconButton>
            <IconButton
              className={classes.botao}
              id="flyout-contato-copiar-telefone"
              onClick={() => copiar(`${ddi}${fone}`)}
            >
              <FileCopyOutlined className={classes.icone} color="primary" />
            </IconButton>
          </Flex>
        </Flex>
      )}
      {email && (
        <Flex justifyContent="space-between" alignItems="center" className={classes.email}>
          <Flex alignItems="center">
            <Email color="primary" className={classes.icone} />
            <Typography color="primary" variant="caption">
              {email}
            </Typography>
          </Flex>
          <Flex className={classes.botoesEmail}>
            {podeEnviarEmail && (
              <IconButton
                className={classes.botao}
                id="flyout-contato-abrir-email"
                onClick={() => {
                  enviarEmail({ cargo, contato: nome, email, lead, leadId });
                  callback();
                }}
              >
                <MailOutlined className={classes.icone} color="primary" />
              </IconButton>
            )}
            <IconButton className={classes.botao} onClick={() => copiar(email)} id="flyout-contato-copiar-email">
              <FileCopyOutlined className={classes.icone} color="primary" />
            </IconButton>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

FlyoutContato.propTypes = {
  nome: PropTypes.string,
  email: PropTypes.string,
  leadId: PropTypes.number,
  enviarEmail: PropTypes.func,
  setModalLigacaoIsOpen: PropTypes.func,
  fone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ddi: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  podeEnviarEmail: PropTypes.bool,
  callback: PropTypes.func.isRequired,
  classes: PropTypes.object,
  lead: PropTypes.string,
  cargo: PropTypes.string,
};

const mapState = ({ emails }) => ({ podeEnviarEmail: isAutenticado({ emails }) });
const mapDispatch = { enviarEmail: sendEmail };

const styled = withStyles({
  icone: {
    fontSize: "14px !important",
    marginRight: 6,
    color: "primary",
    "&:hover, &:active": {
      background: "none",
    },
  },
  botao: {
    width: "32px !important",
    height: "24px !important",
    "&:hover, &:active": {
      background: "none",
    },
  },
  root: {
    marginBottom: 16,
  },
  email: {
    "&:hover $botoesEmail": {
      visibility: "unset",
    },
  },
  botoesEmail: {
    visibility: "hidden",
  },
  fone: {
    "&:hover $botoesFone": {
      visibility: "unset",
    },
  },
  botoesFone: {
    visibility: "hidden",
  },
  flag: {
    margin: "0 6px 0 0",
    width: "18px",
    height: "fit-content",
    boxShadow: "1px 1px 4px 1px rgb(0, 0, 0, 0.2)",
  },
});

export default compose(
  styled,
  connect(
    mapState,
    mapDispatch
  )
)(FlyoutContato);
