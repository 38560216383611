import React from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { ActionButton } from "../../../../components";

CriarFunil.propTypes = {
  funilId: PropTypes.number,
};

export default function CriarFunil({ funilId }) {
  const intl = useIntl();
  const history = useHistory();

  return (
    <ActionButton
      id="btn-criar-funil"
      type="text"
      onClick={() => history.push(`/spotter/base-leads/funil/configurar/${funilId}`, { novoFunil: true })}
      color="primary"
      variant="contained"
      label={intl.formatMessage({ defaultMessage: "Criar Funil" })}
    />
  );
}
