import React, { useRef, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import axios from "axios";
import PropTypes from "prop-types";
import { RootRef, TableRow, TableCell, IconButton, withStyles, Switch, Tooltip, Radio } from "@material-ui/core";
import { FileCopyOutlined } from "@material-ui/icons";
import Delete from "@material-ui/icons/Delete";
import { mutate } from "swr";
import { useDrop, useDrag } from "react-dnd";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import cn from "classnames";

import createSnackBar from "../../../../_common/utils/snackbar/createSnackbar";
import createSnackbarAPIException from "../../../../_common/utils/snackbar/createSnackbarAPIException";
import { messages, FunisAPI } from "./constants";
import { AjaxBlackout, createDialogConfirm, TooltipTruncate } from "../../../../_common";
import { EditarFunil } from "../../funil/EditarFunil";

ListarFunilRow.propTypes = {
  funil: PropTypes.object,
  onMove: PropTypes.func,
  inativarOrdenacao: PropTypes.bool,
  classes: PropTypes.object,
};

function ListarFunilRow({ funil, onMove, inativarOrdenacao, classes }) {
  const intl = useIntl();
  const [handleRef, dropRef] = [useRef(), useRef()];
  const endpoints = new FunisAPI();
  const switchContainerRef = useRef(null);

  const mutateFunilAtivo = f => {
    mutate(endpoints.listar(), data => data.map(campo => (campo.id === f.id ? { ...f, ativo: !f.ativo } : campo)));
  };

  const mutateExcluirFunil = f => {
    mutate(endpoints.listar(), data => data.filter(campo => campo.id !== f.id));
  };

  const mutateDuplicarFunil = f => {
    mutate(endpoints.listar(), data => data.filter(campo => campo.id !== f.id));
  };

  const mutateAlterarPadrao = f => {
    mutate(endpoints.listar(), data => data.filter(campo => campo.id !== f.id));
  };

  const onChangeStatus = async () => {
    try {
      AjaxBlackout.Show();
      await axios.post(endpoints.alterarStatus(funil.id, !funil.ativo));
      createSnackBar(intl.formatMessage({ defaultMessage: "Status alterado com sucesso." }));
      mutateFunilAtivo(funil);
      onChangeStatusElementDOM();
    } catch (err) {
      createSnackbarAPIException(err);
    } finally {
      AjaxBlackout.Hide();
    }
  };

  const onChangeStatusElementDOM = async () => {
    const input = switchContainerRef.current.querySelector(`input[id="switch-status-fn_${funil.id}"]`);
    const newInput = document.createElement("input");
    newInput.className = input.className;
    newInput.id = input.id;
    newInput.type = "checkbox";
    newInput.value = input.value;

    if (!input.hasAttribute("checked")) newInput.setAttribute("checked", "");
    input.parentNode.replaceChild(newInput, input);
  };

  const remove = async () => {
    try {
      AjaxBlackout.Show();
      await axios.post(endpoints.remover(funil.id));
      mutateExcluirFunil(funil);
      createSnackBar(intl.formatMessage({ defaultMessage: "Excluído com sucesso." }));
    } catch (err) {
      createSnackbarAPIException(err);
    } finally {
      AjaxBlackout.Hide();
    }
  };

  const onRemove = () => {
    createDialogConfirm({
      title: intl.formatMessage({ defaultMessage: "Excluir {nomeFunil}?" }, { nomeFunil: funil.nome }),
      acceptLabel: intl.formatMessage({ defaultMessage: "Excluir" }),
      tamanhoMaximo: "sm",
      callback: accepted => accepted && remove(),
    });
  };

  const duplicate = async () => {
    try {
      AjaxBlackout.Show();
      await axios.post(endpoints.duplicar(funil.id));
      mutateDuplicarFunil(funil);
      createSnackBar(intl.formatMessage({ defaultMessage: "Funil duplicado com sucesso." }));
    } catch (err) {
      createSnackbarAPIException(err);
    } finally {
      AjaxBlackout.Hide();
    }
  };

  const onDuplicate = () => {
    createDialogConfirm({
      title: intl.formatMessage({ defaultMessage: "Duplicar {nomeFunil}?" }, { nomeFunil: funil.nome }),
      acceptLabel: intl.formatMessage({ defaultMessage: "Duplicar" }),
      text: intl.formatMessage(
        {
          defaultMessage:
            "Você deseja duplicar o funil {nome} com todas as etapas? O novo funil será criado inativado.",
        },
        { nome: funil.nome }
      ),
      tamanhoMaximo: "sm",
      callback: accepted => accepted && duplicate(),
    });
  };

  const onSave = () => window.location.reload();

  const alterarPadrao = async () => {
    try {
      AjaxBlackout.Show();
      if (!funil.ativo) {
        onChangeStatus();
      }
      await axios.post(endpoints.alterarPadrao(funil.id));
      mutateAlterarPadrao(funil);
      createSnackBar(intl.formatMessage({ defaultMessage: "Funil Padrão alterado com sucesso." }));
    } catch (err) {
      createSnackbarAPIException(err);
    } finally {
      AjaxBlackout.Hide();
    }
  };

  const onChangePadrao = () => {
    createDialogConfirm({
      title: intl.formatMessage({ defaultMessage: "Alterar Funil Padrão" }),
      acceptLabel: intl.formatMessage({ defaultMessage: "Sim" }),
      text: intl.formatMessage({
        defaultMessage:
          "O funil selecionado será ativado e funcionalidades como BA, Importação de leads via API e outras serão ligadas ao novo funil padrão. Essa configuração será utilizada para todos os usuários do Spotter. Deseja continuar com essa operação?",
      }),
      tamanhoMaximo: "sm",
      callback: accepted => (accepted ? alterarPadrao() : mutateAlterarPadrao(funil)),
    });
  };

  const [{ dragging }, drag, preview] = useDrag({
    type: "FUNIL",
    item: () => funil,
    collect: monitor => ({ dragging: monitor.isDragging() }),
    canDrag: () => !inativarOrdenacao,
  });

  const [{ hovered }, drop] = useDrop({
    accept: "FUNIL",
    drop: item => onMove(item, funil),
    canDrop: item => item.id !== funil.id,
    collect: monitor => ({ hovered: monitor.isOver() && monitor.canDrop() }),
  });

  useEffect(
    () => {
      drag(handleRef);
      drop(preview(dropRef));
    },
    [handleRef, dropRef]
  );

  const canDrag = useMemo(() => !inativarOrdenacao, [inativarOrdenacao]);

  if (!funil) return null;

  return (
    <RootRef rootRef={dropRef}>
      <TableRow selected={hovered} className={cn(classes.row, { [classes.dragging]: dragging, canDrag })}>
        <TableCell>
          <div ref={handleRef}>
            <DragIndicatorIcon
              style={{ cursor: inativarOrdenacao ? "default" : "grab", opacity: inativarOrdenacao ? 0.3 : 1 }}
            />
          </div>
        </TableCell>

        <TableCell>
          <TooltipTruncate>{funil.nome}</TooltipTruncate>
        </TableCell>

        <TableCell align="center">
          <Tooltip
            title={
              funil.padrao
                ? intl.formatMessage({
                    defaultMessage: "Funil padrão não pode ser desativado",
                  })
                : intl.formatMessage({ defaultMessage: "Alterar status" })
            }
          >
            <div ref={switchContainerRef}>
              <Switch
                id={`switch-status-fn_${funil.id}`}
                color="primary"
                onClick={onChangeStatus}
                checked={funil.ativo}
                disabled={funil.padrao}
              />
            </div>
          </Tooltip>
        </TableCell>

        <TableCell align="center">
          {funil.padrao ? (
            <Tooltip title={intl.formatMessage({ defaultMessage: "Funil padrão" })}>
              <Radio checked value={funil.id} onChange={onChangePadrao} name={`radio-button-funil-${funil.id}`} />
            </Tooltip>
          ) : (
            <Radio value={funil.id} onChange={onChangePadrao} name={`radio-button-funil-${funil.id}`} />
          )}
        </TableCell>

        <TableCell className={classes.cell} align="right">
          <div className={classes.actionsContainer}>
            <Tooltip title={intl.formatMessage({ defaultMessage: "Duplicar" })} position="top" duration={0}>
              <IconButton id={`btn-duplicar-fn_${funil.id}`} onClick={onDuplicate}>
                <FileCopyOutlined />
              </IconButton>
            </Tooltip>
            <EditarFunil onSave={onSave} funilId={funil?.id} />
            <Tooltip title={intl.formatMessage(funil.padrao ? messages.removerDisabledPadrao : messages.remover)}>
              <div>
                <IconButton id={`btn-remove-fn_${funil.id}`} disabled={funil.padrao} onClick={onRemove}>
                  <Delete />
                </IconButton>
              </div>
            </Tooltip>
          </div>
        </TableCell>
      </TableRow>
    </RootRef>
  );
}

export default withStyles({
  row: {
    "&:hover $actionsContainer": {
      visibility: "visible",
    },
    "&:hover $checkb": {
      color: "#f7f7f7 !important",
    },
  },
  cell: {
    padding: 0,
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    visibility: "hidden",
  },
  checkb: {
    color: "#fff",
  },
  dragging: { opacity: 0.3 },
})(ListarFunilRow);
