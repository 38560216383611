import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useIntl } from "react-intl";
import useSWR from "swr";

import { FormikAsyncSelect } from "../../../../../../components/AsyncSelect";
import { Flex } from "../../../../../../_common";
import EtapaPermissoesGrupoLista from "./EtapaPermissoesGrupoLista";
import useEtapaPermissoes from "../Hooks/useEtapaPermissoes";

EtapaPermissoesGrupo.propTypes = {
  novaEtapa: PropTypes.bool,
  index: PropTypes.number,
  formName: PropTypes.string,
  etapa: PropTypes.object,
  classes: PropTypes.object,
};

function EtapaPermissoesGrupo({ novaEtapa, index, formName, etapa, classes }) {
  const intl = useIntl();
  const { validar } = useEtapaPermissoes();
  const { values, setFieldValue } = useFormikContext();
  const { data: gruposAtivos, isValidating } = useSWR("/api/Pipeline/Grupo/Listar?somenteAtivos=true");

  const alterarGrupo = item => {
    let listaAtualizada = [];
    if (novaEtapa) listaAtualizada = alterarGrupoNovaEtapa(item.value.filter(x => x !== undefined));
    else listaAtualizada = alterarGrupoEdicao(item.value.filter(x => x !== undefined));

    setFieldValue(`${formName}permissaoGrupos`, listaAtualizada);
    setFieldValue(`${formName}idsGrupo`, item.value.filter(x => x !== undefined));
  };

  const alterarGrupoEdicao = lista => {
    const listaAtualizada = [];
    if (values.etapas[index].permissaoGrupos.length > 0) {
      lista.forEach(element => {
        const item = values.etapas[index].permissaoGrupos.filter(x => x !== undefined).find(x => x.grupoId === element);
        if (item !== undefined) listaAtualizada.push(item);
        else listaAtualizada.push({ grupoId: element, permissao: null });
      });
    }

    return listaAtualizada;
  };

  const alterarGrupoNovaEtapa = lista => {
    const listaAtualizada = [];
    if (values.permissaoGrupos.length > 0) {
      lista.forEach(element => {
        const item = values.permissaoGrupos.filter(x => x !== undefined).find(x => x.grupoId === element);
        if (item !== undefined) listaAtualizada.push(item);
        else listaAtualizada.push({ grupoId: element, permissao: null });
      });
    }

    return listaAtualizada;
  };

  useEffect(
    () => {
      if (!isValidating) {
        const listaGruposAtivos = [];
        if (etapa?.idsGrupo?.length > 0) {
          etapa.idsGrupo.forEach(element => {
            const item = gruposAtivos.find(x => x.id === element);
            if (item !== undefined) listaGruposAtivos.push(item.id);
          });
        }
        setFieldValue(`${formName}idsGrupo`, listaGruposAtivos);
      }
    },
    [isValidating]
  );

  return (
    <>
      <Flex className={classes.content}>
        <FormikAsyncSelect
          label={intl.formatMessage({ defaultMessage: "Grupos" })}
          searchLabel={intl.formatMessage({ defaultMessage: "Pesquisar por grupo" })}
          getLabel={item => item.nome}
          getInputLabel={item => item.nome}
          options={gruposAtivos}
          name={`${formName}idsGrupo`}
          urlCriarNovo={`/spotter/equipe/grupos`}
          criarNovo
          enableSearch
          type="multiple"
          validate={validar}
          className={classes.selectGrupos}
          onChange={e => alterarGrupo(e.target)}
        />
      </Flex>
      <Flex flexDirection="column" alignItems="column" className={classes.content}>
        {!novaEtapa
          ? values.etapas[index].idsGrupo.map((item, i) => (
              <EtapaPermissoesGrupoLista
                key={i}
                formName={formName}
                grupoId={item}
                index={i}
                gruposAtivos={gruposAtivos}
              />
            ))
          : values.idsGrupo.map((item, i) => (
              <EtapaPermissoesGrupoLista
                key={i}
                formName={formName}
                grupoId={item}
                index={i}
                gruposAtivos={gruposAtivos}
              />
            ))}
      </Flex>
    </>
  );
}

export default withStyles({
  content: {
    width: "100%",
  },
  selectGrupos: {
    width: "100%",
    margin: 0,
  },
})(EtapaPermissoesGrupo);
