import React from "react";
import PropTypes from "prop-types";

import useSWR from "swr";
import { defineMessages, useIntl } from "react-intl";

import { TIPO_GATE, TIPO_APLICACAO_QUESTIONARIO } from "../../../../../../_common/constantes";
import SelecaoAutomatica from "../SelecaoAutomatica";
import { FormikAsyncSelect } from "../../../../../../components/AsyncSelect";
import { useTipoPlanoSpotter } from "../../../../../../hooks";

EtapaQuestionario.propTypes = {
  novaEtapa: PropTypes.bool,
  index: PropTypes.number,
  formName: PropTypes.string,
  etapa: PropTypes.object,
  questionarios: PropTypes.array,
  classes: PropTypes.object,
  etapas: PropTypes.array,
};

const errorMessages = defineMessages({
  obrigatorio: { defaultMessage: "Campo obrigatório *" },
  muitoLongo: { defaultMessage: "O nome deve possuir 50 ou menos caracteres." },
  impeditivos: { defaultMessage: "A etapa não pode ser criada entre duas etapas que possuem impeditivos." },
});

function EtapaQuestionario({ novaEtapa, index, formName, etapa, questionarios, classes, etapas }) {
  const intl = useIntl();
  const CAMPO_OBRIGATORIO = intl.formatMessage(errorMessages.obrigatorio);
  const { data: lista, mutate: funcaoRecarregar } = useSWR("/api/pipeline/questionario/listar");
  const atualizarLista = lista.length !== questionarios.length;
  let listaQuestionarios = atualizarLista ? lista : questionarios;
  const isSpotterFull = useTipoPlanoSpotter();
  listaQuestionarios = isSpotterFull
    ? listaQuestionarios
    : listaQuestionarios.filter(
        questionario =>
          !etapas.map(e => e.questionario.id).includes(questionario.id) || questionario.id === etapa.questionario.id
      );

  return (
    <>
      {etapa.tipoGate === TIPO_GATE.FILTRO && (
        <>
          <FormikAsyncSelect
            validate={value => (value ? undefined : CAMPO_OBRIGATORIO)}
            name={`${formName}questionario.id`}
            id={`questionario-etapa_${index}`}
            options={listaQuestionarios}
            label={intl.formatMessage({ defaultMessage: "Filtro padrão" })}
            InputLabelProps={etapa.questionario?.id ? { shrink: true } : null}
            criarNovo
            urlCriarNovo={"/spotter/configuracoes/filtros"}
            fullWidth
            funcaoRecarregar={funcaoRecarregar}
            className={classes.field}
            enableSearch
            required={novaEtapa}
          />
          {isSpotterFull && (
            <SelecaoAutomatica
              disabled={etapa.tipoAplicacaoQuestionario !== TIPO_APLICACAO_QUESTIONARIO.DINAMICO}
              etapaId={etapa?.id}
              nameTipoAplicacao={`${formName}tipoAplicacaoQuestionario`}
              nameRegras={`${formName}regras`}
            />
          )}
        </>
      )}
    </>
  );
}

export default EtapaQuestionario;
