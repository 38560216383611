import React from "react";
import PropTypes from "prop-types";
import { Typography, FormControlLabel, Checkbox, Tooltip, withStyles } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { useIntl } from "react-intl";
import HelpIcon from "@material-ui/icons/Help";
import EtapaPermissoesPerfil from "./EtapaPermissoesPerfil";
import EtapaPermissoesGrupo from "./EtapaPermissoesGrupo";
import { Flex, createDialogConfirm } from "../../../../../../_common";

EtapaPermissoes.propTypes = {
  novaEtapa: PropTypes.bool,
  index: PropTypes.number,
  formName: PropTypes.string,
  etapa: PropTypes.object,
  classes: PropTypes.object,
};

function EtapaPermissoes({ novaEtapa, index, formName, etapa, classes }) {
  const intl = useIntl();
  const { values, setFieldValue } = useFormikContext();

  const selecionarGrupos = item => {
    if (item.checked) {
      createDialogConfirm({
        title: intl.formatMessage({ defaultMessage: "Seleção por grupo" }),
        text: intl.formatMessage({
          defaultMessage:
            "A seleção de grupos por etapa pode impactar a visualização das métricas e relatórios de Dashboard e Business Analytics. Deseja continuar?",
        }),
        acceptLabel: intl.formatMessage({ defaultMessage: "Confirmar" }),
        cancelLabel: intl.formatMessage({ defaultMessage: "Cancelar" }),
        callback: accepted => accepted && setFieldValue(`${formName}possuiSelecaoGrupo`, true),
        tamanhoMaximo: "sm",
      });
    } else setFieldValue(`${formName}possuiSelecaoGrupo`, item.checked);
  };

  const exibirPermissoesPerfil = () => {
    if (!novaEtapa && !values.etapas[index].possuiSelecaoGrupo) return true;
    if (novaEtapa && !values.possuiSelecaoGrupo) return true;

    return false;
  };

  const exibirPermissoesGrupo = () => {
    if (!novaEtapa && values.etapas[index].possuiSelecaoGrupo) return true;
    if (novaEtapa && values.possuiSelecaoGrupo) return true;

    return false;
  };

  return (
    <>
      <Flex>
        <Typography className={classes.titulo}>{intl.formatMessage({ defaultMessage: "Permissões" })}</Typography>
        <Tooltip
          className={classes.icone}
          title={intl.formatMessage({
            defaultMessage: `Usuários que não estiverem em grupos selecionados terão a permissão "Oculta" como padrão`,
          })}
        >
          <HelpIcon className={classes.helpIcon} fontSize="small" />
        </Tooltip>
      </Flex>
      <Flex>
        <Field
          fullWidth
          as={FormControlLabel}
          checked={novaEtapa ? values.possuiSelecaoGrupo : values.etapas[index].possuiSelecaoGrupo}
          control={
            <Checkbox
              name={`${formName}possuiSelecaoGrupo`}
              value={novaEtapa ? false : etapa.possuiSelecaoGrupo}
              onChange={e => selecionarGrupos(e.target)}
            />
          }
          label={intl.formatMessage({ defaultMessage: "Selecionar por grupos" })}
          name={`${formName}possuiSelecaoGrupo`}
        />
      </Flex>

      {exibirPermissoesPerfil() && (
        <EtapaPermissoesPerfil
          novaEtapa={novaEtapa}
          index={index}
          formName={formName}
          etapa={etapa}
          classes={classes}
        />
      )}

      {exibirPermissoesGrupo() && (
        <EtapaPermissoesGrupo novaEtapa={novaEtapa} index={index} formName={formName} etapa={etapa} classes={classes} />
      )}
    </>
  );
}

export default withStyles({
  titulo: {
    fontSize: 16,
    fontWeight: 500,
  },
  icone: {
    marginLeft: 8,
  },
})(EtapaPermissoes);
