import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useIntl } from "react-intl";

import { FormikAsyncSelect } from "../../../../../../components/AsyncSelect";
import { PERMISSAO_ETAPA, LABELS_PERMISSAO_ETAPA } from "../../../../../../_common/constantes";
import useEtapaPermissoes from "../Hooks/useEtapaPermissoes";

EtapaPermissoesGrupoLista.propTypes = {
  formName: PropTypes.string,
  grupoId: PropTypes.number,
  index: PropTypes.number,
  classes: PropTypes.object,
  gruposAtivos: PropTypes.array,
};
function EtapaPermissoesGrupoLista({ formName, grupoId, index, gruposAtivos, classes }) {
  const intl = useIntl();
  const { validar } = useEtapaPermissoes();
  const { setFieldValue } = useFormikContext();
  const [mostrarSelect, setMostrarSelect] = useState(false);

  const listaPermissoesGrupo = [
    { id: PERMISSAO_ETAPA.INTERAGIR, descricao: intl.formatMessage(LABELS_PERMISSAO_ETAPA[PERMISSAO_ETAPA.INTERAGIR]) },
    {
      id: PERMISSAO_ETAPA.VISUALIZAR,
      descricao: intl.formatMessage(LABELS_PERMISSAO_ETAPA[PERMISSAO_ETAPA.VISUALIZAR]),
    },
  ];

  useEffect(
    () => {
      if (gruposAtivos === undefined || !gruposAtivos.find(x => x.id === grupoId)) setMostrarSelect(false);
      else setMostrarSelect(true);
    },
    [gruposAtivos]
  );

  return (
    <>
      {grupoId &&
        mostrarSelect && (
          <FormikAsyncSelect
            label={gruposAtivos?.find(x => x.id === grupoId).nome}
            options={listaPermissoesGrupo}
            name={`${formName}permissaoGrupos[${index}].permissao`}
            fullWidth
            enableSearch
            validate={validar}
            onChange={e =>
              setFieldValue(`${formName}permissaoGrupos[${index}]`, { grupoId, permissao: e.target.value })
            }
            className={classes.select}
          />
        )}
    </>
  );
}

export default withStyles({
  select: {
    width: "100%",
    marginTop: 16,
    marginBottom: 0,
  },
})(EtapaPermissoesGrupoLista);
