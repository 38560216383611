import React from "react";
import PropTypes from "prop-types";
import { TextField } from "formik-material-ui";
import { MenuItem, Tooltip } from "@material-ui/core";
import { FastField } from "formik";
import HelpIcon from "@material-ui/icons/Help";

import { useIntl } from "react-intl";

import { TIPO_GATE, LABELS_TIPO_GATE } from "../../../../../../_common/constantes";
import { createDialogConfirm } from "../../../../../../_common";

EtapaTipoGate.propTypes = {
  novaEtapa: PropTypes.bool,
  index: PropTypes.number,
  formName: PropTypes.string,
  etapa: PropTypes.object,
  classes: PropTypes.object,
  onAlterarGate: PropTypes.func,
};

const acoes = [TIPO_GATE.FILTRO, TIPO_GATE.AGENDAMENTO, TIPO_GATE.MANUAL, TIPO_GATE.VENDA];

function EtapaTipoGate({ novaEtapa, index, formName, etapa, classes, onAlterarGate }) {
  const intl = useIntl();

  const alterarTipoGate = novoGate => {
    if (etapa.tipoGate !== novoGate && etapa.funilId !== undefined) {
      createDialogConfirm({
        title: intl.formatMessage({
          defaultMessage: "Alterar a ação de saída",
        }),
        text: intl.formatMessage({
          defaultMessage:
            "A edição da ação de saída da etapa pode impactar as métricas e relatórios de Dashboard e Business Analytics. Deseja confirmar?",
        }),
        acceptLabel: intl.formatMessage({ defaultMessage: "Confirmar" }),
        cancelLabel: intl.formatMessage({ defaultMessage: "Cancelar" }),
        tamanhoMaximo: "sm",
        callback: confirmed => {
          if (confirmed) {
            onAlterarGate(novoGate);
          }
        },
      });
    } else onAlterarGate(novoGate);
  };

  return (
    <>
      <Tooltip
        title={
          etapa.totalLeads
            ? intl.formatMessage({
                defaultMessage: "A ação de saída não pode ser alterada, pois a etapa possui leads",
              })
            : ""
        }
      >
        <FastField
          className={classes.field}
          component={TextField}
          name={`${formName}tipoGate`}
          id={`tipo-gate-etapa_${index}`}
          label={intl.formatMessage({ defaultMessage: "Ação de saída da etapa" })}
          required={novaEtapa}
          disabled={!!etapa.totalLeads}
          select
          InputProps={
            novaEtapa
              ? {
                  endAdornment: (
                    <Tooltip
                      title={intl.formatMessage({
                        defaultMessage:
                          "Os leads passam para a etapa seguinte a partir de uma dessas ações. Para saber mais sobre cada item de passagem entre etapas acesse a central de ajuda.",
                      })}
                    >
                      <HelpIcon />
                    </Tooltip>
                  ),
                }
              : { onChange: e => alterarTipoGate(e.target.value) }
          }
        >
          {acoes.map(acao => (
            <MenuItem id={`menu-item-tipo-gate_${acao}`} key={acao} value={acao}>
              {intl.formatMessage(LABELS_TIPO_GATE[acao])}
            </MenuItem>
          ))}
        </FastField>
      </Tooltip>
    </>
  );
}

export default EtapaTipoGate;
