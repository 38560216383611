import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { cancelarDialogo, confirmarDialogo } from "../actions";

const DialogoConfirmacao = ({ open, titulo, texto, labelConfirmar, labelCancelar, cancelar, confirmar }) => (
  <Dialog open={open} PaperProps={{ square: true }}>
    {titulo && <DialogTitle>{titulo}</DialogTitle>}
    {texto && (
      <DialogContent>
        {Array.isArray(texto) ? (
          texto.map((txt, index) => <DialogContentText key={index}>{txt}</DialogContentText>)
        ) : (
          <DialogContentText>{texto}</DialogContentText>
        )}
      </DialogContent>
    )}
    {(labelCancelar || labelConfirmar) && (
      <DialogActions>
        {labelCancelar && (
          <Button key="cancelarDiaglogo" onClick={cancelar}>
            {labelCancelar}
          </Button>
        )}
        {labelConfirmar && (
          <Button key="confirmarDiaglogo" color="primary" onClick={confirmar}>
            {labelConfirmar}
          </Button>
        )}
      </DialogActions>
    )}
  </Dialog>
);

DialogoConfirmacao.propTypes = {
  open: PropTypes.bool,
  titulo: PropTypes.string,
  texto: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  labelConfirmar: PropTypes.string,
  labelCancelar: PropTypes.string,
  confirmar: PropTypes.func,
  cancelar: PropTypes.func,
};

const mapState = ({ flyout: { dialogo } }) => ({
  open: dialogo.open,
  titulo: dialogo.titulo,
  texto: dialogo.texto,
  labelConfirmar: dialogo.labelConfirmar,
  labelCancelar: dialogo.labelCancelar,
});

const mapDispatch = {
  cancelar: cancelarDialogo,
  confirmar: confirmarDialogo,
};

export default connect(
  mapState,
  mapDispatch
)(DialogoConfirmacao);
