import React from "react";
import { useIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";
import { Tooltip } from "@material-ui/core";

import { useImpeditivos } from "../../../../hooks";
import { ActionButton } from "../../../../components";
import { createDialogConfirm, createSnackbar } from "../../../../_common/";

ExcluirEtapa.propTypes = {
  etapa: PropTypes.object,
  impeditivo: PropTypes.object,
  closeMenu: PropTypes.func,
  onDelete: PropTypes.func,
  funilId: PropTypes.number,
};

const messages = defineMessages({
  impeditivos: {
    defaultMessage: "A etapa não pode ser excluída, pois possui {operacoes} configurada(s)",
  },
  totalLeads: {
    defaultMessage: "A etapa não pode ser excluída, pois possui leads.",
  },
});

function ExcluirEtapa({ etapa, impeditivo, funilId, onDelete = x => x, closeMenu, ...props }) {
  const intl = useIntl();
  const operacoes = useImpeditivos(impeditivo?.impeditivos);

  function excluir() {
    createDialogConfirm({
      title: intl.formatMessage({
        defaultMessage: "Excluir etapa",
      }),

      text: intl.formatMessage({
        defaultMessage:
          "Todas as configurações e registros associados a esta etapa serão apagados. Deseja excluir este etapa?",
      }),

      tamanhoMaximo: "sm",

      callback: confirmed => {
        if (confirmed) {
          onDelete(true);
          closeMenu();
          createSnackbar(
            intl.formatMessage({
              defaultMessage: "Ação concluída com sucesso!",
            })
          );
        }
      },
    });
  }

  return (
    <Tooltip
      title={
        etapa.totalLeads || impeditivo?.impeditivos.length
          ? intl.formatMessage(etapa.totalLeads ? messages.totalLeads : messages.impeditivos, { operacoes })
          : ""
      }
    >
      <span>
        <ActionButton
          id="btn-excluir-etapa"
          type="menu"
          disabled={!!etapa.totalLeads || !!impeditivo?.impeditivos.length}
          onClick={excluir}
          label={intl.formatMessage({ defaultMessage: "Excluir etapa" })}
          {...props}
        />
      </span>
    </Tooltip>
  );
}

export default ExcluirEtapa;
