import { connect } from "react-redux";
import Flyout from "./Flyout";

import { closeFlyout, toggleVenda, buscarLead } from "./actions";
import { buscarLeads } from "../../pipeline/actions";

export { default as actions } from "./actions";
export { default as reducer } from "./reducer";

const mapState = ({ flyout }) => ({
  leadId: flyout.leadId,
  etapaId: flyout.etapaId,
  etapa: flyout.etapa,
  lead: flyout.lead,
  modalVenderAberto: flyout.vendaAberta,
  funilId: flyout.etapa.funilId,
});
// FIXME mover state/dispatch pro componente e mudar sintaxe
const mapDispatch = dispatch => ({
  buscarLead: id => dispatch(buscarLead(id)),
  onCloseFlyout: () => dispatch(closeFlyout()),
  fecharModalVenda: () => dispatch(toggleVenda(false)),
  reload: (etapaId, etapaAteAnterior, zerar, funilId) =>
    dispatch(buscarLeads(etapaId, etapaAteAnterior, zerar, funilId)),
});

export default connect(
  mapState,
  mapDispatch
)(Flyout);
