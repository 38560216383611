import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dialog, Tooltip } from "@material-ui/core";
import { useIntl } from "react-intl";

import { ActionButton } from "../../../../../components";
import { Flex, createSnackbar } from "../../../../../_common/";

import ModalCriarEtapa from "./ModalCriarEtapa";
import { useTipoPlanoSpotter } from "../../../../../hooks";

CriarEtapa.propTypes = {
  etapas: PropTypes.array,
  impeditivos: PropTypes.array,
  onCreate: PropTypes.func,
};

export default function CriarEtapa({ etapas, impeditivos, onCreate }) {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const desabilitar = !useTipoPlanoSpotter() && etapas.length >= 8;
  const toggle = () => setOpen(!open);

  async function onClose(etapa) {
    if (etapa) {
      onCreate(etapa);
      createSnackbar(
        intl.formatMessage({
          defaultMessage: "Ação concluída com sucesso!",
        })
      );
    }
    toggle();
  }

  return (
    <>
      <Tooltip title={desabilitar ? intl.formatMessage({ defaultMessage: "Limite de etapas atingido" }) : ""}>
        <Flex>
          <ActionButton
            variant="contained"
            id="btn-criar-etapa"
            type="text"
            color="primary"
            label={intl.formatMessage({ defaultMessage: "Criar etapa" })}
            onClick={() => toggle()}
            disabled={desabilitar}
          />
        </Flex>
      </Tooltip>
      <Dialog open={open} fullWidth maxWidth="sm">
        <ModalCriarEtapa onClose={onClose} etapas={etapas} impeditivos={impeditivos} />
      </Dialog>
    </>
  );
}
