import React from "react";
import PropTypes from "prop-types";
import { TextField } from "formik-material-ui";
import { MenuItem, TextField as TTextField } from "@material-ui/core";
import { Field } from "formik";
import { useIntl, defineMessages } from "react-intl";

import { LABELS_TIPO_IMPEDITIVO } from "../../../../../../_common/constantes";

EtapaPosicao.propTypes = {
  novaEtapa: PropTypes.bool,
  changeOrdem: PropTypes.func,
  impeditivos: PropTypes.array,
  formName: PropTypes.string,
  index: PropTypes.number,
  etapas: PropTypes.array,
  classes: PropTypes.object,
};

const errorMessages = defineMessages({
  obrigatorio: { defaultMessage: "Campo obrigatório *" },
  muitoLongo: { defaultMessage: "O nome deve possuir 50 ou menos caracteres." },
  impeditivos: {
    defaultMessage:
      "Não é possível criar uma etapa entre {etapaAnterior} e {etapaSeguinte}, pois possuem {impeditivos} configurada(s).",
  },
});

function EtapaPosicao({ novaEtapa, changeOrdem, impeditivos, formName, index, etapas, classes }) {
  const intl = useIntl();

  function showImpeditivos(impeditivo) {
    let op = "";
    if (impeditivo)
      impeditivo.forEach(imp => {
        op = `${op ? `${op}, ` : ""}${intl.formatMessage(LABELS_TIPO_IMPEDITIVO[imp])}`;
      });
    return op;
  }

  return (
    <>
      {novaEtapa ? (
        <Field
          className={classes.field}
          component={TextField}
          label={intl.formatMessage({ defaultMessage: "Posição" })}
          id={`posicao-etapa_${index}`}
          name={`${formName}ordem`}
          required
          select
          validate={value => {
            const impeditivosEtapaAnterior = impeditivos.find(i => i.id === etapas[value - 1]?.id);
            const impeditivosEtapaSeguinte = impeditivos.find(i => i.id === etapas[value]?.id);
            if (impeditivosEtapaAnterior?.impeditivos.length && impeditivosEtapaSeguinte?.impeditivos.length)
              return intl.formatMessage(errorMessages.impeditivos, {
                etapaAnterior: impeditivosEtapaAnterior.nome,
                etapaSeguinte: impeditivosEtapaSeguinte.nome,
                impeditivos: showImpeditivos(impeditivosEtapaAnterior.impeditivos),
              });
            return undefined;
          }}
        >
          <MenuItem id="menu-item-ordem_0" key="inicio" value={0}>
            {intl.formatMessage({ defaultMessage: "Início" })}
          </MenuItem>
          {etapas.map(({ id, nome }, i) => (
            <MenuItem id={`menu-item-ordem_${i + 1}`} key={id} value={i + 1}>
              {intl.formatMessage({ defaultMessage: "Após {etapa}" }, { etapa: nome })}
            </MenuItem>
          ))}
        </Field>
      ) : (
        <TTextField
          className={classes.field}
          label={intl.formatMessage({ defaultMessage: "Posição" })}
          id={`posicao-etapa_${index}`}
          value={index}
          select
          onChange={changeOrdem}
          disabled
        >
          <MenuItem id="menu-item-ordem_0" key="inicio" value={0}>
            {intl.formatMessage({ defaultMessage: "Início" })}
          </MenuItem>
          {etapas.map(({ id, nome }, i) => (
            <MenuItem id={`menu-item-ordem_${i + 1}`} key={id} value={i + 1}>
              {intl.formatMessage({ defaultMessage: "Após {etapa}" }, { etapa: nome })}
            </MenuItem>
          ))}
        </TTextField>
      )}
    </>
  );
}

export default EtapaPosicao;
