import React, { useMemo } from "react";
import cn from "classnames";
import { injectIntl, useIntl } from "react-intl";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { useDispatch, useSelector } from "react-redux";
import MoreVert from "@material-ui/icons/MoreVert";
import { Paper, Tooltip } from "@material-ui/core";
import { useDrag } from "react-dnd";
import CardLeadActions from "../CardLeadActions/CardLeadActions";
import { STATUS_ATIVIDADE, COR_ATIVIDADE } from "../../../constantesBaseLead";
import { Tipografia, Flex, TooltipTruncateV0 } from "../../../../../_common";
import { PERMISSAO_ETAPA, SITUACAO_REUNIAO } from "../../../../../_common/constantes";
import injectSheet from "../../../../../_common/hocs/injectSheet/injectSheet";
import { openFlyout } from "../../Flyout/actions";
import permissions from "../../../../../_common/permissions";
import { usePermission, useUsuario } from "../../../../../hooks";
import { formatCurrency } from "../../../../../_common/utils/string";
import usePermissaoEtapa from "../../../pipeline/components/usePermissaoEtapa";

const definirCorStatusAtividade = statusAtividade => {
  switch (statusAtividade) {
    case STATUS_ATIVIDADE.ATRASADA:
      return COR_ATIVIDADE.ATRASADA;
    case STATUS_ATIVIDADE.PARA_HOJE:
      return COR_ATIVIDADE.PARA_HOJE;
    case STATUS_ATIVIDADE.FUTURA:
      return COR_ATIVIDADE.PROXIMOS_DIAS;
    default:
      return COR_ATIVIDADE.SEM_ATIVIDADE;
  }
};

const styles = theme => ({
  root: {
    position: "relative",
    minHeight: 64,
    borderRadius: 2,
    marginBottom: 8,
    padding: 4,
    borderLeft: ({ lead }) => `3px solid ${definirCorStatusAtividade(lead.statusAtividade)}`,
    "&:hover $containerAcoesPadroes": {
      visibility: "visible",
    },
    "&:hover $moreIcon": {
      visibility: "unset",
    },
    marginRight: 4,
  },
  destaqueLeadNovo: {
    minWidth: 10,
    height: 10,
    backgroundColor: theme.palette.accent1Color,
    borderRadius: "50%",
    margin: 4,
  },
  moreIcon: {
    fontSize: "18px",
    visibility: "hidden",
    cursor: "pointer",
  },
  truncate: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  reuniaoConfirmada: {
    whiteSpace: "nowrap",
    overflowX: "hidden",
    textOverflow: "ellipsis",
    color: "#fff",
    backgroundColor: "#4CAF50",
    padding: "2px 8px",
    borderRadius: "52px",
  },
  espacamentoDestaqueReuniaoConfirmada: {
    minHeight: 18,
    marginTop: 6,
  },
  draggable: {
    cursor: "grab",
  },
  dragging: {
    opacity: 0.5,
  },
  // Estilos utilizados pelo componente <CardLeadActions />
  containerAcoes: { position: "absolute", bottom: 0, right: 8 },
  containerAcoesPadroes: { visibility: "hidden" },
  confirmacao: {
    color: theme.palette.text.darkSecondaryText,
    visibility: "visible",
  },
  icone20: {
    width: "20px !important",
    height: "20px !important",
    marginLeft: 4,
    cursor: "pointer",
  },
  // Estilos utilizados pelo componente <CardLeadActions />
});

const selectDragAndDrop = state => state.pipeline.funil.dragAndDrop;
function CardLead({ lead, faseLead, classes }) {
  const intl = useIntl();
  const usuario = useUsuario();
  const dispatch = useDispatch();
  const dndAtivado = useSelector(selectDragAndDrop);
  const canGoToDetail = usePermission(permissions.VISUALIZAR_DETALHE, lead);
  const permissaoEtapa = usePermissaoEtapa(faseLead);
  const podeInteragirNaEtapa = usuario.isGerente || permissaoEtapa === PERMISSAO_ETAPA.INTERAGIR;

  const [, drag] = useDrag({
    type: "LEAD",
    item: { lead, etapa: faseLead },
    canDrag: () => {
      if (!dndAtivado) return false;
      return !usuario.featureToggles.arrastarLeads && podeInteragirNaEtapa;
    },
  });
  const mostrarValorCalculado = lead.valor !== undefined && lead.valor !== 0;

  const canDrag = useMemo(
    () => {
      if (!dndAtivado) return false;
      return !usuario.featureToggles.arrastarLeads && podeInteragirNaEtapa;
    },
    [dndAtivado, usuario.isGerente, permissaoEtapa, usuario.featureToggles.arrastarLeads]
  );

  function handleOpenFlyout(e) {
    e.preventDefault();
    const { top, left } = e.currentTarget.getBoundingClientRect();
    const { id, preVendedorId } = lead;
    const { gate, gateAnterior, id: etapaId } = faseLead;
    const flyoutProps = {
      leadId: id,
      preVendedorId,
      gate: gate.tipoGate,
      gateAnterior,
      etapaId,
      etapa: faseLead,
    };
    dispatch(openFlyout({ top, left }, flyoutProps));
  }

  return (
    <div ref={drag} className={cn({ [classes.draggable]: canDrag })}>
      <Paper className={classes.root}>
        <Flex alignItems="center" justifyContent="space-between">
          <TooltipTruncateV0
            id={`cardLead_nome_${lead.id}`}
            cor="darkPrimaryText"
            url={canGoToDetail ? `/spotter/detalhes-lead/${lead.id}` : null}
            placement="bottom"
          >
            {lead.nome}
          </TooltipTruncateV0>
          {lead.novo && <div className={classes.destaqueLeadNovo} />}
          <MoreVert className={classes.moreIcon} onClick={handleOpenFlyout} id={`${lead.id}_open-flyout`} />
        </Flex>

        {lead.preVendedor && (
          <Flex alignItems="center" justifyContent="flex-start">
            <Tooltip title={lead.preVendedor} placement="bottom">
              <Tipografia
                id={`cardLead_pv_${lead.id}`}
                tipo="notaLegendas"
                cor="darkSecondaryText"
                className={classes.truncate}
              >
                {lead.preVendedor}
              </Tipografia>
            </Tooltip>
          </Flex>
        )}

        <div style={{ marginTop: "auto", minHeight: 20 }} className={classes.truncate}>
          {lead.agendamento &&
          (lead.agendamento.situacao === SITUACAO_REUNIAO.VIGENTE ||
            lead.agendamento.situacao === SITUACAO_REUNIAO.REAGENDADA) ? (
            <div className={lead.agendamento?.confirmacao ? classes.espacamentoDestaqueReuniaoConfirmada : ""}>
              <Tooltip
                title={
                  lead.agendamento?.confirmacao ? intl.formatMessage({ defaultMessage: "Reunião confirmada" }) : ""
                }
              >
                <Tipografia
                  tipo="notaLegendas"
                  cor="darkSecondaryText"
                  className={lead.agendamento?.confirmacao ? classes.reuniaoConfirmada : classes.truncate}
                >
                  {intl.formatDate(lead.agendamento.data, {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })}
                </Tipografia>
              </Tooltip>
            </div>
          ) : (
            <Tooltip title={lead.complemento || ""}>
              <Tipografia
                id={`cardLead_complemento_${lead.id}`}
                tipo="notaLegendas"
                cor="darkSecondaryText"
                className={classes.truncate}
              >
                {lead.complemento}
              </Tipografia>
            </Tooltip>
          )}
        </div>

        {mostrarValorCalculado && (
          <Flex alignItems="center" justifyContent="flex-start">
            <Tooltip title={lead.valor} placement="bottom">
              <Tipografia
                id={`cardLead_valorNegociacao_${lead.id}`}
                tipo="notaLegendas"
                cor="darkSecondaryText"
                className={classes.truncate}
              >
                {formatCurrency(lead.valor)}
              </Tipografia>
            </Tooltip>
          </Flex>
        )}
        {podeInteragirNaEtapa && (
          <Flex style={{ height: 20 }}>
            <CardLeadActions lead={lead} {...{ faseLead }} {...{ classes }} />
          </Flex>
        )}
      </Paper>
    </div>
  );
}

CardLead.propTypes = {
  lead: PropTypes.object,
  usuario: PropTypes.object,
  faseLead: PropTypes.object,
  classes: PropTypes.object,
  intl: PropTypes.object,
  handleOpenFlyout: PropTypes.func,
  connect: PropTypes.func,
};

const enhance = compose(
  injectIntl,
  injectSheet(styles)
);

export default enhance(CardLead);
