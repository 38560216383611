import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useField, FieldArray } from "formik";
import { Checkbox, FormControlLabel, Tooltip, IconButton, Grid, Collapse, withStyles } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { useIntl } from "react-intl";
import useSWR from "swr";

import SelectQuestionario from "../../../../../components/SelectQuestionario";
import SelectRegra from "../../../../../components/SelectRegra";
import { Flex } from "../../../../../_common";
import { TIPO_APLICACAO_QUESTIONARIO, TIPO_QUESTIONARIO } from "../../../../../_common/constantes";

SelecaoAutomatica.propTypes = {
  nameRegras: PropTypes.string,
  nameTipoAplicacao: PropTypes.string,
  etapaId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  classes: PropTypes.object,
};

function SelecaoAutomatica({ nameRegras, nameTipoAplicacao, classes, disabled, etapaId, ...props }) {
  const intl = useIntl();
  const [fieldTipoAplicacao, , { setValue: setValueTipoAplicacao }] = useField(nameTipoAplicacao, props);
  const [fieldRegras, , { setValue: setValueRegras }] = useField(nameRegras, props);
  const { data } = useSWR("/api/pipeline/SegmentacaoRegra/listar");

  const regras = data?.filter(r => !r.usuarioId);

  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(e => !e);

  const validate = v =>
    fieldTipoAplicacao.value === TIPO_APLICACAO_QUESTIONARIO.DINAMICO && !v
      ? intl.formatMessage({ defaultMessage: "Campo obrigatório" })
      : undefined;

  useEffect(
    () => {
      setExpanded(!disabled);
    },
    [disabled]
  );

  useEffect(
    () => {
      if (!fieldRegras?.value?.length) setValueRegras([{ questionarioId: "", regraId: "" }]);
    },
    [fieldRegras]
  );

  return (
    <FieldArray name={nameRegras}>
      {({ remove, push }) => (
        <>
          <Flex>
            <FormControlLabel
              control={
                <Checkbox
                  checked={fieldTipoAplicacao.value === TIPO_APLICACAO_QUESTIONARIO.DINAMICO}
                  onChange={(e, checked) =>
                    setValueTipoAplicacao(
                      checked ? TIPO_APLICACAO_QUESTIONARIO.DINAMICO : TIPO_APLICACAO_QUESTIONARIO.PADRAO
                    )
                  }
                />
              }
              label={intl.formatMessage({ defaultMessage: "Seleção automática de questionário" })}
            />
            <Tooltip
              title={intl.formatMessage({
                defaultMessage:
                  "Essa opção habilita o uso de regras de segmentação, através das configurações, para seleção do questionário a ser aplicado ao iniciar o filtro de etapas com saída do tipo Filtrar",
              })}
            >
              <HelpIcon className={classes.helpIcon} fontSize="small" />
            </Tooltip>

            <IconButton
              id={`button-expandir`}
              disabled={!fieldRegras.value?.length || disabled}
              onClick={toggleExpanded}
            >
              {expanded && fieldRegras.value?.length ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>

            <IconButton
              disabled={disabled}
              onClick={() => {
                if (!expanded) toggleExpanded();
                push({ questionarioId: "", regraId: "" });
              }}
            >
              <AddIcon />
            </IconButton>
          </Flex>
          <Collapse className={classes.collapse} in={expanded}>
            {fieldRegras.value?.map((value, index) => (
              <Grid container spacing={8} key={index} alignItems="center">
                <Grid item xs={11}>
                  <SelectQuestionario
                    id={`select-regra-questionario-${index}`}
                    tipos={[TIPO_QUESTIONARIO.QUESTIONARIO_LEAD, TIPO_QUESTIONARIO.FEEDBACK]}
                    label={intl.formatMessage({ defaultMessage: "Questionário" })}
                    name={`${nameRegras}.${index}.questionarioId`}
                    disableItem={id =>
                      fieldRegras?.value.some(fr => fr.regraId === value.regraId && fr.questionarioId === id)
                    }
                    validate={validate}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={1}>
                  {fieldRegras.value.length > 1 && (
                    <IconButton
                      onClick={() => {
                        remove(index);
                        if (!fieldRegras.length === 1) setExpanded(false);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Grid>

                <Grid item xs={11}>
                  <SelectRegra
                    regras={regras}
                    disableItem={id =>
                      fieldRegras?.value.some(fr => fr.regraId === id && fr.questionarioId === value.questionarioId)
                    }
                    name={`${nameRegras}.${index}.regraId`}
                    fullWidth
                    validate={validate}
                  />
                </Grid>
              </Grid>
            ))}
          </Collapse>
        </>
      )}
    </FieldArray>
  );
}

export default withStyles({
  helpIcon: { marginTop: 8 },
  collapse: { minHeight: 70, marginBottom: 20 },
})(SelecaoAutomatica);
