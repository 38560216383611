import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import CardLeadActions from "./CardLeadActions.jsx";

import { openFlyout } from "../../Flyout/actions";
import { recarregarEtapa } from "../../../pipeline/actions";
import { abrirModalCancelarReuniao } from "../../../../../store/reducers/baseLeads/baseLeadsActions";
import { handleOpenModalDescarteLead } from "../../../../../store/reducers/detalhesLead/detalhesLeadActions";
import { AjaxBlackout } from "../../../../../_common/index";

class CardLeadActionsContainer extends React.PureComponent {
  handleIniciarFiltro = () => {
    AjaxBlackout.Show();
    window.location.href = `/PreVendedor/Filtros?pLeadId=${this.props.lead.id}&pFaseLeadId=0&pProximaFase=true`;
  };

  handleAgendar = () => {
    window.location.href = `/spotter/reuniao?leadId=${this.props.lead.id}`;
  };

  handleReAgendar = () => {
    window.location.href = `/spotter/reuniao?leadId=${this.props.lead.id}&reagendamento=true`;
  };

  handleCancelarReuniao = () => this.props.abrirModalCancelarReuniao(this.props.lead.id);

  handleDescartar = () => this.props.handleOpenModalDescarteLead(this.props.lead.id.toString());

  render() {
    return (
      <CardLeadActions
        moverLivremente={this.props.moverLivremente}
        lead={this.props.lead}
        etapa={this.props.faseLead}
        handleIniciarFiltro={this.handleIniciarFiltro}
        handleAgendar={this.handleAgendar}
        handleReAgendar={this.handleReAgendar}
        handleCancelarReuniao={this.handleCancelarReuniao}
        handleDescartar={this.handleDescartar}
        classes={this.props.classes}
        handleOpenFlyout={this.props.handleOpenFlyout}
        handleMoveLead={this.props.handleMoveLead}
        isGerente={this.props.isGerente}
        isPreVendedor={this.props.isPreVendedor}
        perfil={this.props.perfil}
      />
    );
  }
}

CardLeadActionsContainer.propTypes = {
  lead: PropTypes.object,
  faseLead: PropTypes.object,
  isGerente: PropTypes.bool,
  isPreVendedor: PropTypes.bool,
  abrirModalCancelarReuniao: PropTypes.func,
  handleOpenModalDescarteLead: PropTypes.func,
  handleOpenFlyout: PropTypes.func,
  handleMoveLead: PropTypes.func,
  perfil: PropTypes.number,
  classes: PropTypes.object,
  moverLivremente: PropTypes.bool,
};

const mapDispatchToProps = dispatch => ({
  abrirModalCancelarReuniao: leadId => dispatch(abrirModalCancelarReuniao(leadId)),
  handleOpenModalDescarteLead: leadId => dispatch(handleOpenModalDescarteLead(leadId)),
  handleOpenFlyout: (anchor, leadId) => dispatch(openFlyout(anchor, leadId)),
  handleMoveLead: (etapaOrigem, etapaDestino) => {
    dispatch(recarregarEtapa(etapaOrigem));
    dispatch(recarregarEtapa(etapaDestino));
  },
});

export default connect(
  ({ usuario }) => ({
    isGerente: usuario.isGerente,
    isPreVendedor: usuario.isPreVendedor,
    perfil: usuario.tipoPerfil,
  }),
  mapDispatchToProps
)(CardLeadActionsContainer);
