import { defineMessages } from "react-intl";

export const messages = defineMessages({
  funil: {
    defaultMessage: "Funil",
  },
  nome: {
    defaultMessage: "Nome",
  },
  ativo: {
    defaultMessage: "Ativo",
  },
  padrao: {
    defaultMessage: "Padrão",
  },
  remover: {
    defaultMessage: "Remover",
  },
  listafunis: {
    defaultMessage: "Lista de funis",
  },
  removerDisabledPadrao: {
    defaultMessage: "O Funil padrão não pode ser removido",
  },
});

export class FunisAPI {
  listar() {
    return `/Api/Pipeline/Funil/Listar?somenteAtivos=false&ordenarPosicao=true`;
  }

  listarTemplates() {
    return `/Api/Pipeline/FunilTemplate/Listar`;
  }

  salvar() {
    return `/Api/Pipeline/CampoPersonalizado/Salvar?isOrganizacao=${this.isOrganizacao}`;
  }

  alterarStatus(id, ativo) {
    return `/Api/Pipeline/Funil/AlterarStatus?id=${id}&ativo=${ativo}`;
  }

  remover(id) {
    return `/Api/Pipeline/Funil/Remover?id=${id}`;
  }

  duplicar(id) {
    return `/Api/Pipeline/Funil/Clonar?id=${id}`;
  }

  alterarPadrao(id) {
    return `/Api/Pipeline/Funil/Padrao?id=${id}`;
  }
}
