import React from "react";
import ContentLoader from "react-content-loader";

import { Paper } from "@material-ui/core";

const SkeletonCardLead = () => (
  <Paper
    style={{
      height: 64,
      borderRadius: 2,
      marginRight: 4,
      marginBottom: 8,
      paddingLeft: 4,
    }}
  >
    <ContentLoader style={{ height: "100%", width: "100%" }}>
      <rect x="0" y="15" rx="0" ry="0" width="340" height="30" />
      <rect x="0" y="95" rx="0" ry="0" width="200" height="25" />
    </ContentLoader>
  </Paper>
);

export default SkeletonCardLead;
