import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import useSWR from "swr";
import axios from "axios";
import { Formik, Form, FieldArray } from "formik";
import { dequal } from "dequal";
import { set } from "dot-prop-immutable";
import { useParams, useLocation } from "react-router";
import { Avatar, DialogContent, DialogTitle, Paper, Tooltip, Typography, withStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import MoreVert from "@material-ui/icons/MoreVert";
import { useIntl } from "react-intl";
import { usePageTitle } from "../../../../hooks";

import { CriarEtapa, DuplicarEtapa, ExcluirEtapa } from "./";
import {
  TIPO_GATE,
  LABELS_TIPO_GATE,
  SIGLAS_TIPO_GATE,
  TIPO_APLICACAO_QUESTIONARIO,
} from "../../../../_common/constantes";
import { AnchorMenu } from "../../../../components";
import { buscarEtapas } from "../../pipeline/actions";
import {
  Flex,
  Divider,
  createSnackbarAPIException,
  Loading,
  Tipografia,
  createDialogConfirm,
  createSnackbar,
  AjaxBlackout,
} from "../../../../_common";
import Etapa from "./Etapa";
import BarraSuperiorConfigurarFunil from "./BarraSuperiorConfigurarFunil";

ConfigurarFunil.propTypes = {
  classes: PropTypes.object,
};

function ConfigurarFunil({ classes }) {
  const intl = useIntl();
  const location = useLocation();
  const novoFunil = location?.state?.novoFunil !== undefined;
  const { funilId } = useParams();

  usePageTitle(intl.formatMessage({ defaultMessage: "Configuração do Funil - Exact Sales" }));

  const { data: questionarios } = useSWR("/api/pipeline/questionario/listar");

  const { data: funil, isValidating: validatingFunil } = useSWR(`/api/pipeline/funil/buscar?id=${funilId}`, {
    dedupingInterval: 0,
  });
  const { data: impeditivos, isValidating: validatingImpeditivos } = useSWR(
    `/api/pipeline/Funil/VerificarImpeditivos?funilId=${funilId}`,
    {
      dedupingInterval: 0,
    }
  );

  const dispatch = useDispatch();
  const [erros, setErros] = useState([]);

  const [nomeFunil, setNomeFunil] = useState("");

  const validate = useCallback(
    values => {
      let errors = {};
      const gerais = [];
      const ultimaEtapa = values.etapas[values.etapas.length - 1];
      const etapasVenda = values.etapas.filter(etapa => etapa.tipoGate === TIPO_GATE.VENDA);

      if (etapasVenda.length > 1)
        values.etapas.forEach((etapa, index) => {
          if (etapa.tipoGate === TIPO_GATE.VENDA)
            errors = set(
              errors,
              `etapas.${index}.tipoGate`,
              intl.formatMessage({ defaultMessage: "Não pode existir mais de uma etapa de venda no funil." })
            );
        });

      if (ultimaEtapa.tipoGate === TIPO_GATE.VENDA)
        gerais.push(
          intl.formatMessage({
            defaultMessage: `É obrigatória uma etapa após uma passagem do tipo "venda"`,
          })
        );

      return gerais.length > 0 ? { ...errors, geral: gerais } : errors;
    },
    [intl, funil]
  );

  function mostrarNovosErros(novosErros) {
    dispatch(buscarEtapas(funilId));
    setErros(
      novosErros.map(val =>
        intl.formatMessage(
          {
            defaultMessage:
              "Houve uma nova alteração na etapa {nome} que bloqueia a operação. A quantidade de leads ou as integrações mudaram. Verifique a base de leads.",
          },
          { nome: val.nome }
        )
      )
    );
  }

  function compararImpeditivos(novosImpeditivos, novasEtapas) {
    const diferencaImpeditivos = novosImpeditivos.filter((x, index) => !dequal(x, impeditivos[index]));

    const diferencaEtapas = diferencaImpeditivos.length
      ? funil.etapas.filter(e => {
          const etp = novasEtapas.find(et => et.id === e.id);

          if (
            !etp &&
            diferencaImpeditivos.find(i => i.id === e.id) &&
            !dequal(e, { ...etp, regras: !etp?.regras[0]?.questionarioId ? [] : etp.regras })
          )
            return e;
          return null;
        })
      : [];

    return [...diferencaEtapas];
  }

  function tocarCamposVenda(etapas, setFieldTouched) {
    etapas.forEach((e, i) => {
      if (e.tipoGate === TIPO_GATE.VENDA) {
        setFieldTouched(`etapas[${i + 1}].tipoGate`, true);
      }
    });
  }

  async function salvar(values) {
    try {
      const { data: success } = await axios
        .get(`/api/pipeline/Funil/VerificarImpeditivos?funilId=${funilId}`)
        .then(({ data: newImpeditivos }) => {
          AjaxBlackout.Show();

          // Compara os arrays de impeditivos e de etapas, retorna um array com as etapas alteradas que possuem impeditivos diferentes
          const diferencaImpeditivos = compararImpeditivos(newImpeditivos, values.etapas);

          // Caso não tenha diferença salva normalmente
          if (!diferencaImpeditivos.length) {
            const etapas = values.etapas.map((etapa, index) => ({
              id: typeof etapa.id === "number" ? etapa.id : 0,
              nome: etapa.nome,
              ordem: index + 1,
              permissoes: etapa.permissoes,
              questionarioId: etapa.questionario?.id,
              questionarioUsuarioId: etapa.questionarioUsuarioId,
              tipoGate: etapa.tipoGate,
              tipoAplicacaoQuestionario: etapa.tipoAplicacaoQuestionario,
              regras: etapa.tipoAplicacaoQuestionario === TIPO_APLICACAO_QUESTIONARIO.DINAMICO ? etapa.regras : [],
              possuiSelecaoGrupo: etapa.possuiSelecaoGrupo,
              idsGrupo: etapa.idsGrupo,
              permissaoGrupos: etapa.permissaoGrupos,
            }));

            const newValues = {
              id: novoFunil ? 0 : funilId,
              nome: nomeFunil === "" ? funil?.nome : nomeFunil,
              etapas: etapas.map(etapa => ({
                ...etapa,
                regras: etapa.tipoGate === TIPO_GATE.FILTRO ? etapa.regras : [],
                tipoAplicacaoQuestionario:
                  etapa.tipoGate === TIPO_GATE.FILTRO
                    ? etapa.tipoAplicacaoQuestionario
                    : TIPO_APLICACAO_QUESTIONARIO.PADRAO,
              })),
            };

            return axios.post(`/api/pipeline/funil/salvar`, newValues);
          }
          // Caso tenha diferença mostra os erros em cima da modal
          mostrarNovosErros(diferencaImpeditivos);

          return { data: false };
        });

      if (success) window.location.href = "/spotter/base-leads/funil/";
    } catch (e) {
      createSnackbarAPIException(e);
    } finally {
      AjaxBlackout.Hide();
    }
  }

  function exibirConfirmacaoExclusao(values) {
    createDialogConfirm({
      title: intl.formatMessage({
        defaultMessage: "Excluir funil",
      }),
      text: intl.formatMessage({
        defaultMessage:
          "Um funil precisa ter pelo menos uma etapa. Ao salvar um funil sem etapas, ele será excluído. Deseja excluir o funil?",
      }),
      acceptLabel: intl.formatMessage({ defaultMessage: "Excluir funil" }),
      cancelLabel: intl.formatMessage({ defaultMessage: "Voltar para a edição" }),
      tamanhoMaximo: "sm",
      callback: confirmed => {
        if (confirmed) {
          salvar(values);
          createSnackbar(
            intl.formatMessage({
              defaultMessage: "Funil excluído com sucesso!",
            })
          );
        }
      },
    });
  }

  if (validatingFunil || !questionarios || validatingImpeditivos) return <Loading isLoading />;

  return (
    <>
      <Formik
        initialValues={{ etapas: funil.etapas }}
        validate={validate}
        enableReinitialize
        onSubmit={async values => {
          try {
            if (!values.etapas.length) {
              exibirConfirmacaoExclusao(values);
            } else {
              salvar(values);
            }
          } catch (e) {
            createSnackbarAPIException(e);
          } finally {
            AjaxBlackout.Hide();
          }
        }}
      >
        {({ values, errors, setFieldTouched, setFieldValue }) => (
          <Form>
            <FieldArray name="etapas">
              {({ insert, remove }) => (
                <>
                  <BarraSuperiorConfigurarFunil
                    etapas={values.etapas}
                    novoFunil={novoFunil}
                    funil={funil}
                    setNomeFunil={setNomeFunil}
                  />
                  <div>
                    {errors.geral?.length > 0 && (
                      <DialogTitle className={classes.header}>
                        <Flex
                          alignItems="flex-start"
                          justifyContent={errors.geral?.length > 0 || erros.length > 0 ? "space-between" : "flex-end"}
                        >
                          {(errors.geral?.length > 0 || erros.length > 0) && (
                            <Flex className={classes.errors} flexDirection="column" alignItems="flex-start">
                              <Typography color="error">
                                {intl.formatMessage({ defaultMessage: "Existem erros no funil" })}
                              </Typography>

                              {errors.geral?.map(err => (
                                <Typography key={err} color="error">
                                  <li>{err}</li>
                                </Typography>
                              ))}
                              {erros?.map(err => (
                                <Typography key={err} color="error">
                                  <li>{err}</li>
                                </Typography>
                              ))}
                            </Flex>
                          )}
                        </Flex>
                      </DialogTitle>
                    )}

                    <DialogContent className={values.etapas.length > 0 ? classes.content : classes.nenhumaEtapa}>
                      <Flex>
                        {values.etapas.map((etapa, index) => (
                          <Flex key={index}>
                            <Paper elevation={0} square className={classes.etapa}>
                              <div className={classes.actionsContainer}>
                                <AnchorMenu id={`menu-etapa-${index}`} icon={<MoreVert className={classes.moreIcon} />}>
                                  <DuplicarEtapa
                                    id={`duplicar-etapa-${index}`}
                                    etapa={etapa}
                                    impeditivo={impeditivos.find(i => i.id === etapa.id)}
                                    onDuplicate={() => {
                                      const etapaDuplicada = {
                                        ...etapa,
                                        totalLeads: 0,
                                        id: `$VIRTUAL${Date.now()}_${Math.random()}`,
                                        nome: intl.formatMessage(
                                          { defaultMessage: "Cópia de {nome}" },
                                          { nome: etapa.nome }
                                        ),
                                      };

                                      insert(index + 1, etapaDuplicada);

                                      tocarCamposVenda(values.etapas, setFieldTouched);
                                      setFieldTouched(`etapas[${index}].tipoGate`, true);
                                    }}
                                  />
                                  <ExcluirEtapa
                                    id={`excluir-etapa-${index}`}
                                    etapa={etapa}
                                    impeditivo={impeditivos.find(i => i.id === etapa.id)}
                                    onDelete={success => {
                                      if (success) {
                                        const etapas = [...values.etapas];
                                        etapas.splice(index, 1);

                                        remove(index);
                                      }
                                    }}
                                  />
                                </AnchorMenu>
                              </div>
                              <Etapa
                                questionarios={questionarios}
                                etapa={etapa}
                                etapas={values.etapas.filter(e => !e.isExcluido)}
                                formName={`etapas[${index}].`}
                                index={index}
                                style={{ maxWidth: 300, minWidth: 300 }}
                                onAlterarGate={novoGate => {
                                  setFieldValue(`etapas[${index}].tipoGate`, novoGate);
                                  setFieldValue(`etapas[${index}].id`, `$VIRTUAL${Date.now()}_${Math.random()}`);
                                }}
                              />
                            </Paper>

                            <div className={classes.gateway}>
                              <Tooltip
                                title={intl.formatMessage(
                                  { defaultMessage: "Modo de passagem entre etapas: {etapaGate}" },
                                  { etapaGate: intl.formatMessage(LABELS_TIPO_GATE[etapa.tipoGate]) }
                                )}
                              >
                                <Avatar className={classes.avatar}>
                                  {intl.formatMessage(SIGLAS_TIPO_GATE[etapa.tipoGate])}
                                </Avatar>
                              </Tooltip>
                              <Divider style={{ height: "100%" }} />
                            </div>
                          </Flex>
                        ))}
                      </Flex>
                      <Flex center flexDirection="column" className={classes.contentCriarEtapa}>
                        <Tipografia tipo="titulo2" style={{ marginBottom: 20, textAlign: "center" }}>
                          {intl.formatMessage({ defaultMessage: "Adicionar nova etapa" })}
                        </Tipografia>
                        <Tipografia tipo="subtituloConteudo" style={{ marginBottom: 20, textAlign: "center" }}>
                          {intl.formatMessage({
                            defaultMessage: "As etapas do funil representam os passos em seu processo de vendas",
                          })}
                        </Tipografia>
                        <CriarEtapa
                          etapas={values.etapas}
                          impeditivos={impeditivos}
                          onCreate={etapa => {
                            insert(etapa.ordem, etapa);

                            tocarCamposVenda(values.etapas, setFieldTouched);
                            setFieldTouched(`etapas[${etapa.ordem}].tipoGate`, true);
                          }}
                        />
                      </Flex>
                    </DialogContent>
                  </div>
                </>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default withStyles({
  header: { marginBottom: 15 },
  content: {
    maxWidth: "100vw",
    display: "flex",
    alignItems: "center",
  },
  nenhumaEtapa: {
    maxWidth: "100vw",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    paddingTop: "16% !important",
  },
  etapa: { padding: 20 },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    height: 20,
  },
  moreIcon: {
    height: 20,
    marginLeft: 20,
    cursor: "pointer",
  },
  gateway: {
    width: 40,
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginRight: 10,
  },
  avatar: {
    fontSize: 14,
    width: 24,
    height: 24,
  },
  contentCriarEtapa: {
    minWidth: "45vh",
    maxWidth: "45vh",
    overflow: "auto",
    marginRight: 10,
  },
})(ConfigurarFunil);
