import axios from "axios";
import { v4 } from "uuid";
import { defineMessage } from "react-intl";
import { createAction } from "../../../../_common/utils/redux";
import { buscarFasesLead } from "../../../../store/reducers/baseLeads/baseLeadsActions";
import {
  handleConcluirAtividade,
  handleAbrirModalAtividadeEditar,
} from "../../../../store/reducers/atividades/modalAtividadesActions";
import { adicionarEmailTo, abrirEscreverEmail } from "../../../../store/reducers/emails/emailsActions";
import createSnackBar from "../../../../_common/utils/snackbar/createSnackbar";
import { getLead } from "./api";
import { AjaxBlackout, objectKeysToCamelCase } from "../../../../_common";
import { recarregarEtapa, buscarLeads, recarregarAgendamento } from "../../pipeline/actions";

const ACTIONS = {
  ABRIR_FLYOUT_DETALHE_LEAD: "FLYOUT/ABRIR_FLYOUT_DETALHE_LEAD",
  FECHAR_FLYOUT_DETALHE_LEAD: "FLYOUT/FECHAR_FLYOUT_DETALHE_LEAD",
  ABRIR_DIALOGO_CONFIRMACAO: "FLYOUT/ABRIR_DIALOGO_CONFIRMACAO",
  FECHAR_DIALOGO_CONFIRMACAO: "FLYOUT/FECHAR_DIALOGO_CONFIRMACAO",
  EXCLUIR_ATIVIDADE: "FLYOUT/EXCLUIR_ATIVIDADE",
  TOGGLE_VENDA: "FLYOUT/TOGGLE_VENDA",
  BUSCAR_LEAD: "FLYOUT/BUSCAR_LEAD",
};

export default ACTIONS;

export const openFlyout = (anchor, { leadId, preVendedorId, gate, gateAnterior, etapaId, etapa }) =>
  createAction(ACTIONS.ABRIR_FLYOUT_DETALHE_LEAD, {
    anchor,
    args: { leadId, preVendedorId, gate, gateAnterior, etapaId, etapa },
  });
export const closeFlyout = () => createAction(ACTIONS.FECHAR_FLYOUT_DETALHE_LEAD);

export const buscarLead = id => async (dispatch, getState) => {
  const { gate, gateAnterior, preVendedorId } = getState().flyout;
  const lead = await getLead({ id, gate, gateAnterior, preVendedorId });
  dispatch(createAction(ACTIONS.BUSCAR_LEAD, { lead }));
};

export const sendEmail = ({ cargo, contato, email, lead, leadId }) => dispatch => {
  const payload = {
    id: v4(),
    cargo,
    name: contato,
    email,
    text: `${contato} (${email}) - ${lead}`,
    listaLeads: [{ nome: lead, id: leadId }],
  };
  dispatch(adicionarEmailTo(payload));
  dispatch(abrirEscreverEmail());
};

export const toggleVenda = aberta => dispatch => {
  if (!aberta) dispatch(buscarFasesLead());
  dispatch(createAction(ACTIONS.TOGGLE_VENDA, aberta));
  dispatch(closeFlyout());
};

export const editarAtividade = id => async dispatch => {
  dispatch(closeFlyout());
  await dispatch(handleAbrirModalAtividadeEditar(id));
};

export const concluirAtividade = atividade => async dispatch => {
  dispatch(closeFlyout());
  await dispatch(handleConcluirAtividade(atividade));
  dispatch(buscarLeads(atividade.etapa.id, "", true, atividade.etapa.funilId));
};

export const excluirAtividade = (id, etapa) => async dispatch => {
  dispatch(closeFlyout());
  await dispatch(
    createAction(ACTIONS.ABRIR_DIALOGO_CONFIRMACAO, {
      titulo: "Não será possível recuperar essa atividade",
      texto: "Deseja realmente excluí-la?",
      labelConfirmar: "Excluir",
      acao: () => axios.post(`/api/pipeline/atividade/cancelar?id=${id}`),
      onSucesso: () => {
        buscarFasesLead();
        if (etapa) dispatch(buscarLeads(etapa.id, "", true, etapa.funilId));
      },
    })
  );
};

export const duplicarLead = leadId =>
  createAction(ACTIONS.ABRIR_DIALOGO_CONFIRMACAO, {
    titulo: defineMessage({ defaultMessage: "Duplicar Lead" }),
    texto: defineMessage({
      defaultMessage:
        "Ao duplicar o Lead você será levado a tela de edição do lead novo (a cópia).O lead original em nada será alterado, já o novo lead irá para a etapa de Entrada.Deseja duplicar este lead?",
    }),
    labelConfirmar: "Duplicar",
    acao: () => {
      window.location.href = `/CadastroLead?pLeadId=${leadId}&pEstaAlterandoLead=false&pEstaDuplicando=true`;
    },
  });

export const cancelarReuniao = (leadId, etapaId) =>
  createAction(ACTIONS.ABRIR_DIALOGO_CONFIRMACAO, {
    titulo: "Cancelar reunião",
    texto: "Você deseja cancelar a reunião com esse lead?",
    acao: () => axios.post(`/ProjetoPipeline/Reuniao/Cancelar?Leadid=${leadId}`),
    onSucesso: () => recarregarAgendamento(etapaId),
    labelConfirmar: "Cancelar reunião",
    labelCancelar: "Não cancelar reunião",
  });

export const reenviarDiagnostico = leadId =>
  createAction(ACTIONS.ABRIR_DIALOGO_CONFIRMACAO, {
    titulo: "Reenviar diagnóstico do Lead",
    texto: "O diagnóstico do Lead já foi enviado para este vendedor. Deseja enviar novamente?",
    labelConfirmar: "Reenviar",
    acao: () => axios.post(`/Email/EmailAgendamento/ReenviarEmailFolhaLead?pLeadId=${leadId}`),
  });

export const reenviarFeedback = leadId =>
  createAction(ACTIONS.ABRIR_DIALOGO_CONFIRMACAO, {
    titulo: "Reenviar email de feedback de reunião",
    texto: "Um email de feedback já foi enviado para este vendedor. Deseja enviar novamente?",
    acao: () => axios.post(`/NovoDetalheLead/ReenviarEmailFeedback?pLeadId=${leadId}`),
    labelConfirmar: "Reenviar",
  });

export const desfazerVenda = (leadId, etapaId) => async dispatch => {
  AjaxBlackout.Show();
  const { data } = await axios.get(`/api/pipeline/venda/buscaretapa?leadId=${leadId}`);
  const etapa = objectKeysToCamelCase(data);
  AjaxBlackout.Hide();
  dispatch(
    createAction(ACTIONS.ABRIR_DIALOGO_CONFIRMACAO, {
      titulo: "Desfazer venda",
      texto: [
        `Ao desfazer a venda, este lead retornará para a etapa ${etapa.descricao}`,
        "Tem certeza que deseja desfazer a venda?",
      ],
      acao: () => axios.post(`/api/pipeline/venda/desfazer?leadId=${leadId}&etapaId=${etapa.id}`),
      onSucesso: () => {
        dispatch(recarregarEtapa(etapa.id));
        dispatch(recarregarEtapa(etapaId));
      },
      labelCancelar: "Cancelar",
      labelConfirmar: "Desfazer",
    })
  );
};

export const cancelarDialogo = () => createAction(ACTIONS.FECHAR_DIALOGO_CONFIRMACAO);

export const confirmarDialogo = () => async (dispatch, getState) => {
  const { acao, onSucesso, onErro } = getState().flyout.dialogo;
  try {
    AjaxBlackout.Show();
    dispatch(cancelarDialogo());
    const response = await acao();
    if (response.data && response.data.Success && response.data.Texto) {
      createSnackBar(response.data.Texto);
    }
    if (onSucesso) return dispatch(onSucesso(response));
    return response;
  } catch (err) {
    if (onErro) return dispatch(onErro(err));
    return err;
  } finally {
    AjaxBlackout.Hide();
  }
};
