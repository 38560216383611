import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Button, IconButton, Paper, withStyles } from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Edit from "@material-ui/icons/Edit";
import { Flex, Tipografia } from "../../../../_common";
import { CampoEditavel } from "../../pipeline/components";

function BarraSuperiorConfigurarFunil({ novoFunil, funil, setNomeFunil, etapas, classes }) {
  const intl = useIntl();
  const history = useHistory();

  const handleBotaoVoltar = () => {
    history.goBack();
  };

  return (
    <>
      <Paper elevation={2} square>
        <Flex justifyContent="space-between" className={classes.items}>
          <Flex alignItems="center">
            <IconButton onClick={handleBotaoVoltar} style={{ color: "#000" }}>
              <ArrowBack className={classes.icons} />
              <Tipografia tipo="botoes">{intl.formatMessage({ defaultMessage: "Cancelar e voltar" })}</Tipografia>
            </IconButton>
          </Flex>
          <Flex alignItems="center">
            <CampoEditavel
              className={classes.tituloEditavel}
              texto={novoFunil ? "" : funil?.nome}
              onSave={nome => setNomeFunil(nome)}
              placeholder={intl.formatMessage({ defaultMessage: "Nome do funil *" })}
              required
            />
            <Edit />
          </Flex>
          <Flex alignItems="center">
            <Button type="submit" variant="contained" color={!etapas.length ? "inherit" : "primary"}>
              {intl.formatMessage({ defaultMessage: "Salvar" })}
            </Button>
          </Flex>
        </Flex>
      </Paper>
    </>
  );
}

BarraSuperiorConfigurarFunil.propTypes = {
  novoFunil: PropTypes.bool,
  funil: PropTypes.object,
  setNomeFunil: PropTypes.func,
  etapas: PropTypes.array,
  classes: PropTypes.object,
};

export default withStyles({
  items: {
    padding: 20,
  },
  icons: {
    marginRight: 8,
  },
  tituloEditavel: {
    width: 150,
    fontSize: 20,
    fontWeight: 500,
    padding: [0, 5],
    borderRadius: 5,
    marginTop: 2,
    border: "1px solid transparent",
    "&:hover": { borderColor: "lightgray" },
    "&:focus-within": { boxShadow: "0 0 0 2px #457AB7" },
  },
})(BarraSuperiorConfigurarFunil);
