import { useIntl } from "react-intl";

export default function useEtapaPermissoes() {
  const intl = useIntl();

  function validar(value) {
    if (!value || value?.length <= 0) return intl.formatMessage({ defaultMessage: "Campo obrigatório" });
    return undefined;
  }

  return {
    validar,
  };
}
