import React from "react";
import PropTypes from "prop-types";
import { compose } from "lodash/fp";
import { MuiThemeProvider, Menu, IconButton } from "@material-ui/core";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import { set } from "dot-prop-immutable";
import { useDispatch } from "react-redux";

import Flex from "../../../../../_common/components/Flex";
import {
  Duplicar,
  Vender,
  Transferir,
  Excluir,
  ReenviarEmailFeedback,
  ReenviarDiagnostico,
  IniciarFiltro,
  AgendarReuniao,
  ConfirmarReuniao,
  DesfazerVenda,
  Editar,
  VerAtividades,
  RegistrarLigacao,
  CancelarReuniao,
  Descartar,
  ReiniciarCiclo,
} from "../../../../lead/components";
import TransferirVendedor from "../../../../lead/components/TransferirVendedor";
import { CriarAtividade } from "../../../../atividade/components";
import { recarregarEtapa, recarregarEtapaAnterior, recarregarEntrada } from "../../../pipeline/actions";
import { TIPO_GATE } from "../../../../../_common/constantes";
import { useTipoPlanoSpotter } from "../../../../../hooks";

FileiraAcoesFlyout.propTypes = {
  lead: PropTypes.object,
  callback: PropTypes.func,
};

const updateTheme = compose(
  t => set(t, "props.MuiSvgIcon.fontSize", "small"),
  t => set(t, "overrides.MuiIconButton.root.paddingLeft", 6),
  t => set(t, "overrides.MuiIconButton.root.paddingRight", 6)
);

function FileiraAcoesFlyout({ lead, callback }) {
  const [open, setOpen] = React.useState(false);
  const ref = React.useRef(null);
  const dispatch = useDispatch();
  const isSpotterFull = useTipoPlanoSpotter();

  const leadComSituacao = React.useMemo(() => ({ situacao: 1, ...lead }), [lead]);

  const cb = success => {
    setOpen(false);
    callback(success);
  };

  const desfazerVendaCallBack = (success, etapa) => {
    if (success) {
      cb(success);
      dispatch(recarregarEtapa(etapa));
    }
  };

  const cancelarReuniaoCallback = success => {
    if (success) {
      cb(success);
      dispatch(recarregarEtapa(lead.etapa));
      dispatch(recarregarEtapaAnterior(lead.etapaAnteriorId));
    }
  };

  const reiniciarCiclo = leadId => {
    if (leadId) {
      cb(leadId);
      dispatch(recarregarEtapa(lead.etapaId));
      dispatch(recarregarEntrada());
    }
  };

  return (
    <MuiThemeProvider theme={updateTheme}>
      <Flex alignItems="center" justifyContent="flex-end">
        <IniciarFiltro type="icon" lead={lead} />
        <ConfirmarReuniao type="icon" lead={lead} callback={success => cb(success)} />
        <AgendarReuniao type="icon" lead={lead} redirecionarBaseLeads />
        <AgendarReuniao type="icon" lead={lead} reagendar redirecionarBaseLeads />

        <Vender type="icon" lead={leadComSituacao} callback={success => cb(success)} />
        {lead.etapa?.gateAnterior === TIPO_GATE.VENDA && (
          <DesfazerVenda type="icon" lead={leadComSituacao} id={lead.id} callback={desfazerVendaCallBack} />
        )}
        <Editar type="icon" lead={lead} />
        <CriarAtividade type="icon" lead={lead} callBack={success => cb(success)} />
        <VerAtividades type="icon" lead={lead} />

        <IconButton id="flyout-options" onClick={() => setOpen(true)} buttonRef={ref}>
          <MoreHoriz />
        </IconButton>

        <Menu disableAutoFocusItem open={open} onClose={() => setOpen(false)} anchorEl={ref.current}>
          {lead && <RegistrarLigacao type="menu" id={lead.id} callback={cb} />}
          <CancelarReuniao type="menu" lead={lead} callback={cancelarReuniaoCallback} />
          <ReenviarEmailFeedback type="menu" leads={[lead]} callback={success => cb(success)} />
          <ReenviarDiagnostico type="menu" leads={[lead]} callback={success => cb(success)} />
          <Duplicar type="menu" lead={lead} />
          <ReiniciarCiclo callback={reiniciarCiclo} lead={lead} />
          <Transferir type="menu" leads={[lead]} callback={cb} />
          <Descartar type="menu" leads={[lead]} callback={success => cb(success)} />
          {isSpotterFull && <TransferirVendedor type="menu" leads={[lead]} callback={success => cb(success)} />}
          <Excluir type="menu" leads={[lead]} callback={success => cb(success)} />
        </Menu>
      </Flex>
    </MuiThemeProvider>
  );
}

const FlyoutAcoes = ({ lead, etapa, callbackFlyout }) => (
  <FileiraAcoesFlyout lead={{ ...lead, etapa }} callback={callbackFlyout} />
);

FlyoutAcoes.propTypes = {
  etapa: PropTypes.object,
  lead: PropTypes.object,
  callbackFlyout: PropTypes.func,
};

export default FlyoutAcoes;
