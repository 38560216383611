import React, { Fragment, useRef, useEffect, useState } from "react";
import { CardContent, CardHeader, CardActions, Divider, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import moment from "moment";

import { Flex, Loading, Tipografia } from "../../../../_common";
import withStyles from "../../../../_common/hocs/injectSheet";
import ModalDescartarLead from "../../../detalhes-lead/Modal/ModalDescartarLead/ModalDescartarLead";
import ModalNovoEmail from "../../../central-emails/ModalNovoEmail/ModalNovoEmail";
import EmailProvider from "../../../../_common/utils/EmailProvider";
import { FlyoutAcoes, FlyoutAtividades, FlyoutContato, FlyoutFunil, DialogoConfirmacao, Field } from "./components";
import PopoverCard from "./components/PopoverCard";
import { useUsuario, useSelectPermission, useTipoPlanoSpotter } from "../../../../hooks";
import permissions from "../../../../_common/permissions";
import { ACCESS_LEVEL } from "../../../../_common/constantes";
import LigacaoContatosLead from "../../../detalhes-lead/components/LigacaoContatosLead/LigacaoContatosLead";
import { IdsInlineManual } from "../../../../_common/utils/constants/inlineManual";

const Flyout = ({ onCloseFlyout, classes, leadId, etapaId, etapa, lead, reload, buscarLead }) => {
  const intl = useIntl();
  const { isGerente } = useUsuario();
  const [modalLigacaoIsOpen, setModalLigacaoIsOpen] = useState(false);
  const permissaoAtividade = useSelectPermission(permissions.MODULOATIVIDADES);
  const isSpotterFull = useTipoPlanoSpotter();

  useEffect(
    () => {
      if (leadId) buscarLead(leadId);
    },
    [leadId, etapaId]
  );

  const callbackFlyout = retorno => {
    if (retorno) {
      onCloseFlyout();
      reload(etapa.id, etapa.gateAnterior, true, etapa.funilId);
    }
  };

  const update = useRef(null);

  return (
    <Fragment>
      {lead &&
        modalLigacaoIsOpen && (
          <LigacaoContatosLead onClose={() => setModalLigacaoIsOpen(false)} leadId={lead.id.toString()} />
        )}
      <PopoverCard
        keepMounted
        action={({ updatePosition }) => {
          update.current = updatePosition;
        }}
      >
        {!lead ? (
          <Loading isLoading />
        ) : (
          (update && update.current && update.current()) || (
            <Fragment>
              <CardHeader
                className={classes.cardHeader}
                action={
                  <FlyoutAcoes
                    permissoes={lead.permissoes}
                    leadId={leadId}
                    faseLead={lead.id}
                    etapa={etapa}
                    leadNome={lead.nome}
                    lead={{ situacao: 1, ...lead }}
                    fazColetaResultadoLigacao={lead.FazColetaResultadoLigacao}
                    callbackFlyout={callbackFlyout}
                  />
                }
              />
              <Divider />
              <CardContent className={classes.cardBody}>
                {lead.atividade &&
                  (lead.atividade.preVendedorId === lead.preVendedorId ||
                    isGerente ||
                    (permissaoAtividade && permissaoAtividade.nivelAcesso === ACCESS_LEVEL.COMPLETE)) && (
                    <FlyoutAtividades atividade={lead.atividade} lead={lead} etapa={etapa} callback={callbackFlyout} />
                  )}
                {lead.contato && (
                  <FlyoutContato
                    nome={lead.contato.nome}
                    email={lead.contato.email}
                    ddi={lead.contato.ddi}
                    fone={lead.contato.telefone}
                    lead={lead.nome}
                    cargo={lead.contato.cargo}
                    leadId={leadId}
                    callback={onCloseFlyout}
                    setModalLigacaoIsOpen={setModalLigacaoIsOpen}
                  />
                )}
                {lead.funil && (
                  <FlyoutFunil
                    nome={lead.funil.etapaAtual}
                    funilAtual={lead.funilAtual}
                    descricao={lead.funil.ultimaQualificacao}
                    historico={lead.funil.historico}
                    data={lead.funil.tempoNaEtapa}
                    leadId={lead.id}
                  />
                )}
                {lead.vendedor && (
                  <Field
                    label={
                      isSpotterFull
                        ? intl.formatMessage({ defaultMessage: "Vendedor" })
                        : intl.formatMessage({ defaultMessage: "Responsável pela reunião" })
                    }
                  >
                    <Tipografia id={`flyout_vendedor_${lead.id}`}>{lead.vendedor}</Tipografia>
                  </Field>
                )}
                {lead.mercado && (
                  <Field label={intl.formatMessage({ defaultMessage: "Mercado" })}>
                    <Tipografia id={`flyout_mercado_${lead.id}`}>{lead.mercado}</Tipografia>
                  </Field>
                )}
                {lead.origem && (
                  <Field label={intl.formatMessage({ defaultMessage: "Origem" })}>
                    <Tipografia id={`flyout_origem_${lead.id}`}>{lead.origem}</Tipografia>
                  </Field>
                )}
                {lead.subOrigem && (
                  <Field label={intl.formatMessage({ defaultMessage: "Sub-Origem" })}>
                    <Tipografia id={`flyout_suborigem_${lead.id}`}>{lead.subOrigem}</Tipografia>
                  </Field>
                )}
                {lead.dtPrevisaoFechamento && (
                  <Field label={intl.formatMessage({ defaultMessage: "Previsão de fechamento" })}>
                    <Tipografia id={`flyout_dtPrevisaoFechamento_${lead.id}`} className={classes.campoSemData}>
                      {lead.dtPrevisaoFechamento ? moment(lead.dtPrevisaoFechamento).format("DD/MM/YYYY") : "-"}
                    </Tipografia>
                  </Field>
                )}
              </CardContent>
              <Divider />
              <CardActions>
                <Flex flexDirection="row-reverse" alignItems="flex-start" className={classes.cardActions}>
                  <Button
                    id={IdsInlineManual.BTN_DETALHES_LEAD}
                    component={Link}
                    color="primary"
                    variant="contained"
                    to={`/spotter/detalhes-lead/${leadId}`}
                    className={classes.botaoDetalhe}
                    onClick={onCloseFlyout}
                  >
                    {intl.formatMessage({ defaultMessage: "Detalhes do lead" })}
                  </Button>
                </Flex>
              </CardActions>
            </Fragment>
          )
        )}
      </PopoverCard>
      <ModalDescartarLead callback={reload} />
      <ModalNovoEmail />
      <DialogoConfirmacao />
      <EmailProvider />
    </Fragment>
  );
};

Flyout.propTypes = {
  onCloseFlyout: PropTypes.func,
  classes: PropTypes.object,
  leadId: PropTypes.number,
  etapaId: PropTypes.number,
  etapa: PropTypes.object,
  lead: PropTypes.object,
  reload: PropTypes.func,
  buscarLead: PropTypes.func,
};

export default withStyles({
  footer: {
    width: "100%",
    height: 50,
    padding: 0,
    alignItems: "flex-end",
    flexDirection: "row-reverse",
  },
  botaoDetalhe: {
    "&:hover, &:visited, &:active": {
      color: "white",
    },
  },
  cardHeader: {
    height: 50,
  },
  cardActions: {
    width: "100%",
  },
  botaoCardActions: {
    height: 32,
    width: 164,
    borderRadius: 0,
  },
  campoSemData: { paddingLeft: "6px" },
})(Flyout);
