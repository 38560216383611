import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Edit from "@material-ui/icons/Edit";
import { useIntl } from "react-intl";

import { ActionButton } from "../../../../components";

EditarFunil.propTypes = {
  funilId: PropTypes.number,
};

export default function EditarFunil({ funilId }) {
  const intl = useIntl();
  const history = useHistory();

  return (
    <ActionButton
      id="btn-editar-funil"
      type="icon"
      icon={<Edit />}
      onClick={() => history.push(`/spotter/base-leads/funil/configurar/${funilId}`)}
      title={intl.formatMessage({ defaultMessage: "Editar" })}
    />
  );
}
