import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";

import { useIntl } from "react-intl";

EtapaTotal.propTypes = {
  index: PropTypes.number,
  totalLeads: PropTypes.number,
  classes: PropTypes.object,
};

function EtapaTotal({ index, totalLeads, classes }) {
  const intl = useIntl();

  return (
    <TextField
      className={classes.field}
      disabled
      value={totalLeads}
      id={`total-leads-etapa_${index}`}
      label={intl.formatMessage({ defaultMessage: "Quantidade de leads" })}
    />
  );
}

export default EtapaTotal;
