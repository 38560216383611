import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { Tipografia, Flex } from "../../../../../_common";

const Field = ({ label, children, classes }) => (
  <Flex className={classes.root} flexDirection="column">
    <Tipografia cor="darkSecondaryText" tipo="notaLegendas" className={classes.label}>
      {label}
    </Tipografia>
    <Flex justifyContent="space-between" alignItems="flex-start">
      {children}
    </Flex>
  </Flex>
);

Field.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
};

export default withStyles({
  root: {
    minHeight: 40,
    paddingBottom: 16,
  },
  label: {
    paddingBottom: 4,
  },
})(Field);
